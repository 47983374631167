import icon_tab from './img/icon_tab.svg';
import tag_icon from './img/tag_icon.svg';
import StarIcon from '@mui/icons-material/Star';
import sort_icon from './img/sort_icon.svg';
import plug from './img/plug_icon.svg';
import customHeaders, { getSpace } from '../../common/headers';
import banner_no_data from './img/banner-no-data.svg';
import lightning_icon from './img/lightning_icon.svg';
import info_icon from './img/info_icon.svg';

import { useChartData } from '../../common/hooks';
import { Rating } from '@mui/material';
import { BarCharts } from '../../charts/BarCharts/BarCharts';
import { LineChart } from '../../charts/LineChart/LineChart';
import { ReviewLlist } from '../ReviewList/ReviewList';
import { ModalReview } from '../Modal/ModalReview/ModalReview';
import { TooltipBlue } from '../TooltipBlue/TooltipBlue';
import { TabsGreyBtn } from '../TabsGreyBtn/TabsGreyBtn';
import { NoDataContent } from '../NoDataContent/NoDataContent';
import { DoughnutChart } from '../../charts/DoughnutChart/DoughnutChart';
import { FilterContainer } from '../FilterContainer/FilterContainer';
import { getFormatedDate } from '../ChatReview/ChatReview';
import { useState, useEffect, useRef } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  fetchApiReviewsFilter,
  fetchFeedbacksBrandFilter,
  fetchFeedbacksCategoryFilter,
} from '../../common/filtersFunc';
import {
  KonstantinFeedbackHttps,
  KonstantinFeedbackHttp,
  RavshanHttps,
  KonstantinMasterHttps,
  KonstantinMasterHttp,
} from '../../fetchUrls';
import {
  Tabs,
  DropDownSelector,
  RangeBox,
  ButtonBasic,
  CardStatistics,
  DropContainer,
  TableBox,
  MetricsItem,
  InputDynamicWidth,
} from '@lk-gtcom/ecomlab-components';
import { AdvancedAnalytics } from '../../pages/AdvancedAnalytics/AdvancedAnalytics';
import { AdvancedAnalyticsQuestions } from '../../pages/AdvancedAnalyticsQuestions/AdvancedAnalyticsQuestions';
import { TooltipBlack } from '../Tooltip/TooltipBlack/TooltipBlack';
import useGeneralStore from '../../store/general';
import { TableTop5 } from '../TableTop5/TableTop5';
import './AnaliticsReview.scss';
import {
  addFiltersByDataObj,
  addFiltersToUrlQuery,
  getDataObjByFilters,
  getFiltersByUrlQuery,
} from '../../common/utils';

const btns = [
  { label: 'Позитивные', value: 'true' },
  { label: 'Негативные', value: 'false' },
];

const btns_cup = [
  { label: 'Положительные', value: 'positive' },
  { label: 'Отрицательные', value: 'negative' },
];

const charts_names = {
  'feedbacksby-product': [
    { label: 'feedbacksby-product', path: 'number-feedback' },
    { label: 'feedbacksby-product', path: 'change-number-feedback' },
  ],
  'feedbacksby-category': [
    { label: 'feedbacksby-category', path: 'number-feedback' },
    { label: 'feedbacksby-category', path: 'change-number-feedback' },
  ],
  'feedbacksby-brand': [
    { label: 'feedbacksby-brand', path: 'number-feedback' },
    { label: 'feedbacksby-brand', path: 'change-number-feedback' },
  ],

  'questionsby-product': [
    { label: 'questionsby-product', path: 'top_5_products_by_answer_time' },
    { label: 'questionsby-product', path: 'top_5_products_by_questions' },
  ],
  'questionsby-category': [
    { label: 'questionsby-category', path: 'top_5_categories_by_answer_time' },
    { label: 'questionsby-category', path: 'top_5_categories_by_questions' },
  ],
  'questionsby-brand': [
    { label: 'questionsby-brand', path: 'top_5_brands_by_answer_time' },
    { label: 'questionsby-brand', path: 'top_5_brands_by_questions' },
  ],
};

const advanced_names = {
  feedbacks_sales: [
    { path: 'avg_sales_by_feedbacks', type: 'chart' },
    { path: 'avg_sales_by_days', type: 'chart' },
    { path: 'adv_analytics_pivot_avg_sales', type: 'table' },
  ],
  feedbacks_feedbacks: [
    { path: 'avg_views_by_feedbacks', type: 'chart' },
    { path: 'avg_views_by_days', type: 'chart' },
    { path: 'adv_analytics_pivot_avg_views', type: 'table' },
  ],
  feedbacks_feedbackssalesByRating: [
    { path: 'avg_sales_by_rating', type: 'chart' },
    { path: 'avg_views_by_rating', type: 'chart' },
    { path: 'adv_analytics_pivot_avg_rating', type: 'table' },
  ],
  feedbacks_feedbacksnegRatingSales: [
    { path: 'avg_sales_and_neg_rating_by_days', type: 'chart' },
    // { path: 'adv_analytics_top_5_neg_rating', type: 'table' },
    // { path: 'adv_analytics_top_5_without_feedbacks', type: 'table' },
  ],
  questions_number_of_questions: [
    { path: 'number_of_questions', type: 'chart' },
    { path: 'number_of_questions', type: 'table' },
  ],
  questions_average_response_time: [
    { path: 'average_response_time', type: 'chart' },
    { path: 'average_response_time', type: 'table' },
  ],
};

// const charts_list_feedbacks = {
//   questions_average_response_time: [
//     { path: 'number-feedback', type: 'chart' },
//     { path: 'number-feedback', type: 'table' },
//   ],
// }

// const charts_list_feedbacks = [
//   'number-feedback',
//   'change-number-feedback'
// ]

const metrics_names_feedbacks = {
  'by-product': [
    { label: '', path: 'feedback_number' },
    { label: '', path: 'avg_rating' },
    { label: '', path: 'photo_number' },
    { label: '', path: 'avg_feedbacks_per_day' },
    { label: '', path: 'products_number' },
    { label: '', path: 'unanswered_feedback_number' },
  ],
  'by-category': [
    { label: 'Средний рейтинг', path: 'feedback_number' },
    { label: 'Топ 5 категорий по кол-ву отзывов', path: 'products_number' },
    { label: 'Оценки', path: 'avg_rating' },
    { label: 'Оценки', path: 'unanswered_feedback_number' },
  ],
  'by-brand': [
    { label: 'Средний рейтинг', path: 'feedback_number' },
    { label: 'Топ 5 категорий по кол-ву отзывов', path: 'products_number' },
    { label: 'Оценки', path: 'avg_rating' },
    { label: 'Оценки', path: 'unanswered_feedback_number' },
  ],
  categorization: [
    { label: '', path: 'feed-categories' },
    { label: '', path: 'feedbacks' },
    { label: '', path: 'product-valuation' },
    { label: '', path: 'good-values' },
    { label: '', path: 'bad-values' },
    { label: '', path: 'items-count' },
  ],
};

const metrics_names_questions = [
  'question_number',
  'products_number',
  'avg_answer_time',
  'unanswered_question_number',
];

const tooltips = [
  `На данных графиках можно увидеть влияние отзывов на средние продажи.  
   На точечном графике можно увидеть, где больше всего расположены точки, 
   там и существует взамиосвязь средних продаж от отзывов. Также можно увидеть, 
   при каком  количестве отзывов увеличивается уровень продаж. Линейный график 
   показывает измение показателей по дням.
   Внизу (или справа) представлена сводная таблица по изменеию средних продаж 
   при изменении отзывов:`,

  `На данных графиках можно увидеть влияние отзывов на средние просмотры.  
    На точечном графике можно увидеть, где больше всего расположены точки, там и 
    существует взамиосвязь средних просмотров от отзывов. Также можно увидеть, при каком  
    количестве отзывов увеличивается уровень просмотров. Линейный график показывает измение 
    показателей по дням. Внизу (или справа) представлена сводная таблица по изменеию средних 
    просмотров при изменении отзывов:`,

  `На данном графике можно увидеть влияние отрицательных оценок на средние продажи.  
    Если при увеличении отрицательных отзывов в какой-то момент средние продажи уменьшаются, 
    возможно есть влияние отрицательных оценок на сред. продажи. Стоит обратить внимание на 
    данные оценки и написать ответ по отзыву для разъяснения.`,

  // 'На данном графике и первой таблице можно увидеть влияние отрицательных оценок на средние продажи. Стоит обратить внимание на данные товары и написать ответ на отзыв для разъяснения. Во второй таблице можно увидеть топ-товаров, по которым длительное время не было отзывов, стоит обратить на них внимание.'
];

// const options_prop = [
//     {
//         label: 'Ozon',
//         value: { name: 'ozon', id: 1 }
//     },
//     {
//         label: 'Wildberries',
//         value: { name: 'wb', id: 3 }
//     },
//     {
//         label: 'Yandex',
//         value: { name: 'yandex', id: 2 }
//     },
// ]

const options_prop = [
  {
    label: 'Ozon',
    value: 1,
  },
  {
    label: 'Wildberries',
    value: 3,
  },
  {
    label: 'Yandex',
    value: 2,
  },
];

const fetchFilterDataForReviews = async ({
  filterName,
  selectedSpace,
  page = 1,
  searchParam = '',
  selectedFilters = {},
  setFilterData,
  setSelectedFilter,
  append = false,
}) => {
  const url = `${KonstantinMasterHttps}/v3/filter/${filterName}`;

  const formatDate = (timestamp) => {
    if (!timestamp || isNaN(timestamp)) {
      return null;
    }
    const date = new Date(timestamp);
    return date.toISOString().split('T')[0];
  };

  const getFilterList = (filterArray) =>
    filterArray?.length > 0 ? filterArray.map((item) => item.value) : ['all'];

  const selectedIds =
    selectedFilters.selected_id && selectedFilters.selected_id.length > 0
      ? selectedFilters.selected_id.flatMap((id) =>
        typeof id === 'string' ? id.split(';') : id
      )
      : [];

  const payload = {
    search_param: searchParam || null,
    page,
    limit: 50,
    api_id_list: getFilterList(selectedFilters.api),
    category_id_list: getFilterList(selectedFilters.category),
    brand_id_list: getFilterList(selectedFilters.brand),
    mp_id_list: getFilterList(selectedFilters.marketplace),
    product_valuation_list: getFilterList(selectedFilters.rating),
    date_from: formatDate(selectedFilters.dateFrom),
    date_to: formatDate(selectedFilters.dateTo),
    selected_id_list: selectedIds || [],
  };

  const headers = {
    ...customHeaders,
    space: selectedSpace,
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const data = await response.json();

      const formattedData = data.filter_data?.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setFilterData((prevData) =>
        append ? [...prevData, ...formattedData] : formattedData
      );

      if (data.selected?.length) {
        const selectedItems = data.selected.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setSelectedFilter(selectedItems);
      }
    } else {
      console.log(response.statusText);
    }
  } catch (error) {
    console.log(error);
  }
};

export const CardMessageReviewChat = ({ ...data }) => {
  const {
    id,
    product_name,
    product_valuation,
    feedback_text,
    answer_text,
    primary_image,
    tag,
    created_at = new Date(),
    setIsModal,
    setChatId,
  } = data;
  return (
    <div
      className={'message'}
      onClick={(e) => {
        setIsModal(true);
        setChatId(id);
      }}
      key={JSON.stringify(data)}
    >
      <div className="icon-container">
        <img
          src={primary_image ? primary_image : plug}
          onError={(e) => (e.target.src = plug)}
          loading="lazy"
        />
        <div className={answer_text ? 'answered' : 'not-answered'}>
          {answer_text ? 'Отвечен' : 'Не отвечен'}
        </div>
      </div>
      <div className="info-content">
        <h3 className="title">
          {product_name ? product_name : 'Нет названия'}
        </h3>
        <p className="text_feedback">{feedback_text ? feedback_text : '-'}</p>
      </div>
      <div className="rating-and-date">
        {product_valuation && (
          <div className="rating-color-box">
            <Rating
              precision={1}
              defaultValue={product_valuation}
              max={1}
              readOnly
              emptyIcon={<StarIcon style={{ opacity: '.1' }} />}
              icon={
                <StarIcon
                  style={{ fill: '#faaf00', width: '16px', height: '16px' }}
                />
              }
              size="large"
            />
            <p className="text_rating">{product_valuation}</p>
          </div>
        )}
        <TooltipBlack
          position={'absolute'}
          text={tag}
          child={
            <div className="tag-item">
              <img src={tag_icon} />
              <p className="text">{tag ? tag : '-'}</p>
            </div>
          }
        />

        {created_at && (
          <p className="text_date">
            {getFormatedDate(created_at, 'date')}{' '}
            <span>{getFormatedDate(created_at, 'time')}</span>
          </p>
        )}
      </div>
    </div>
  );
};

const AnaliticsReview = ({
  openConnectionModalById,
  isModalActive,
  setIsModalKnowledgeBase,
  pageValue,
  queryFeedbacks,
  setQueryFeedbacks
}) => {
  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };
  const client_id = localStorage.getItem('client_id');
  const btn_tab = [
    {
      label: 'Товары',
      value: 'by-product',
    },
    {
      label: 'По категориям',
      value: 'by-category',
    },
    {
      label: 'По брендам',
      value: 'by-brand',
    },
    {
      label: 'Категоризация отзывов',
      value: 'categorization',
    },
    {
      label: 'По настроению',
      value: 'by-mood',
    },
    {
      label: 'Продвинутая аналитика',
      value: +selectedSpace?.value === 3459 ? 'advanced' : null,
    },
    {
      label: 'Аналитика',
      value: 'analytics',
    },
  ];

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [allPeriod, setAllPeriod] = useState(false);
  const [isNotOneAccount, setIsNotOneAccount] = useState('done');

  const [showTooltipStep1, setShowTooltipStep1] = useState(true);
  const [showTooltipStep2, setShowTooltipStep2] = useState(false);
  const [hideAlwaysTooltip, setHideAlwaysTooltip] = useState(false);

  const [tooltip, setTooltip] = useState(true);

  const paginatorRef = useRef();

  useEffect(() => {
    if (!hideAlwaysTooltip) {
      if (!showTooltipStep1 && !showTooltipStep2) {
        setShowTooltipStep2(true);
      }
    }
  }, [showTooltipStep1]);

  const navigate = useNavigate()

  useEffect(() => {
    setQueryFeedbacks(window?.location?.search)
  }, [window?.location?.search]);


  const activeTab = useParams()['*'];
  const [searchParam, setSearchParam] = useSearchParams();

  const [pageContent, setPageContent] = useState('by-product');
  const [sort, setSort] = useState([]);

  const [sortTags, setSortTags] = useState('ASC');
  const [currentParamTag, setCurrentParamTag] = useState('tag');

  const [sortFeedbacks, setSortFeedbacks] = useState('ASC');
  const [currentParamFeedbacks, setCurrentParamFeedbacks] = useState('Дата');

  const [sortKeywords, setSortKeywords] = useState('ASC');
  const [currentParamKeywords, setCurrentParamKeywords] = useState('keyword');

  const [tableHeaders, setTableHeaders] = useState([]);
  const [column_size, setColumnSize] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [fetchedCharts, setFetchedCharts] = useState([]);
  const [fetchedMetrics, setFetchedMetrics] = useState([]);
  const [loadChart, setLoadChart] = useState(false);
  const [loadMetrics, setLoadMetrics] = useState(false);

  const [setChartData, chartData, setChartFuncs] = useChartData();
  const { setChartLoading, resetChart } = setChartFuncs;

  const [apiData, setApiData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);

  const [selectedApi, setSelectedApi] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [selectedMarketplace, setSelectedMarketplace] = useState([
    { label: 'Все', value: 'all' },
  ]);

  const [apiPage, setApiPage] = useState(1);
  const [categoryPage, setCategoryPage] = useState(1);
  const [brandPage, setBrandPage] = useState(1);
  const [showDropBox, setShowDropBox] = useState(true);

  const [apiQuery, setApiQuery] = useState('');
  const [categoryQuery, setCategoryQuery] = useState('');
  const [brandQuery, setBrandQuery] = useState('');
  const [dateFrom, dateTo] = date;

  const [status, setStatus] = useState('true');
  const [statusCup, setStatusCup] = useState({});

  const keywordsRef = useRef();
  const [keywordsList, setKeywordsList] = useState([]);
  const [checkedKeywords, setCheckedKeywords] = useState([]);
  const [keywordsSearch, setKeywordsSearch] = useState('');
  const [keywordsPage, setKeywordsPage] = useState(1);
  const [keywordsPageHeight, setKeywordsPageHeight] = useState(0);
  const [keywordsPageScrollTop, setKeywordsPageScrollTop] = useState(-1);
  const [keywordsIsSearchLastPage, setKeywrodsIsSearchLastPage] =
    useState(false);
  const [keywordsTotal, setKeywordsTotal] = useState(0);

  const tagsRef = useRef();
  const [tagsList, setTagsList] = useState([]);
  const [checkedTags, setCheckedTags] = useState([]);
  const [tagsSearch, setTagsSearch] = useState('');
  const [tagsPage, setTagsPage] = useState(1);
  const [tagsPageHeight, setTagsPageHeight] = useState(0);
  const [tagsPageScrollTop, setTagsPageScrollTop] = useState(-1);
  const [tagsIsSearchLastPage, setTagsIsSearchLastPage] = useState(false);
  const [tagsTotal, setTagsTotal] = useState(0);

  const feedbacksRef = useRef();
  const [feedbackList, setFeedbackList] = useState([]);
  const [feedbackPage, setFeedbackPage] = useState(1);
  const [feedbackSearch, setFeedbackSearch] = useState('');
  const [feedbackPageHeight, setFeedbackPageHeight] = useState(0);
  const [feedbackPageScrollTop, setFeedbackPageScrollTop] = useState(-1);
  const [feedbackIsSearchLastPage, setFeedbackIsSearchLastPage] =
    useState(false);
  const [feedbacksTotal, setFeedbacksTotal] = useState(0);

  const [questData, setQuestData] = useState({});

  const [isModal, setIsModal] = useState(false);

  const [metricsInfo, setMetricsInfo] = useState({});
  const [metricsInfoLoading, setMetricsInfoLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const [iniitalQuery, setInitialQuery] = useState(true);
  const [defaultDate, setDefaultDate] = useState('');

  useEffect(() => {
    setPageContent(activeTab);
  }, [activeTab]);

  const fetchFilters = ({
    filterName,
    selectedFilters,
    setFilterData,
    setSelectedFilter,
    page = 1,
    searchParam = '',
    append = false,
  }) => {
    fetchFilterDataForReviews({
      filterName,
      selectedSpace: selectedSpace?.value,
      page,
      searchParam,
      selectedFilters,
      setFilterData,
      setSelectedFilter,
      append,
    });
  };

  useEffect(() => {
    if (iniitalQuery) {
      const dataObj = getFiltersByUrlQuery(searchParam);
      addFiltersByDataObj({
        dataObj,
        setSelectedMarketplace,
        setSelectedApi,
        setSelectedCategory,
        setSelectedBrand,
        setDate: setDefaultDate,
        setPageContent,
      });
      setInitialQuery(false);
    }
  }, [searchParam]);

  useEffect(() => {
    if (iniitalQuery) return;
    const dataObj = getDataObjByFilters({
      selectedMarketplace,
      selectedApi,
      selectedBrand,
      selectedCategory,
      date,
      pageContent,
    });
    addFiltersToUrlQuery(dataObj, setSearchParam);
  }, [selectedApi, selectedBrand, selectedCategory, date]);

  const resetTable = () => {
    if (pageContent != 'advanced') {
      paginatorRef.current?.reset();
      paginatorRef.current?.setTotal(0);
    }

    setSort({});
    setTableHeaders([]);
    setFetchedData([]);
  };

  const fetchTableData = (
    params,
    path,
    label,
    abortController = new AbortController()
  ) => {
    if (pageContent == 'by-mood') return;
    let sort;
    let filtersParam = [];

    if (params) {
      [sort, filtersParam] = params;
    }
    setLoading(true);

    const baseRoute =
      (pageContent === 'advanced') & (pageValue !== 'questions')
        ? KonstantinFeedbackHttps
        : KonstantinMasterHttps;
    const baseRouteHttp =
      (pageContent === 'advanced') & (pageValue !== 'questions')
        ? KonstantinFeedbackHttp
        : KonstantinMasterHttp;
    const mp =
      (pageContent === 'advanced') & (pageValue !== 'questions')
        ? 'wildberries'
        : 'wb';

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/wb/${pageValue}/analytics/${pageContent}/table${pageContent === 'advanced' ? `s/${path}` : ''
        }${sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
        }`;
    } else {
      url = `${KonstantinMasterHttps}/wb/${pageValue}/analytics/${pageContent}/table${pageContent === 'advanced' ? `s/${path}` : ''
        }${sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
        }`;
    }

    let api_id_list;
    let brand_id_list;
    let category_id_list;
    let mp_id_list;
    let keyword_list = checkedKeywords;
    const [date_from, date_to] = date;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => el.value);
    }
    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }
    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }
    if (selectedMarketplace?.length > 0) {
      mp_id_list = selectedMarketplace?.map((el) => el.value);
    }

    const body = JSON.stringify({
      page: paginatorRef.current?.page,
      limit: paginatorRef.current?.limit,
      filters: filtersParam,
      mp_id_list,
      category_id_list,
      brand_id_list,
      api_id_list,
      date_from: allPeriod
        ? new Date(0)
        : new Date(date_from + 86401000)?.toISOString()?.split('T')?.[0],
      date_to: new Date(date_to)?.toISOString().split('T')?.[0],
      positive: status === 'true',
      keyword_list,
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        if (pageContent === 'advanced') {
          if (json) {
            setFetchedCharts((prev) => {
              const obj = { ...prev };
              if (obj[label]) {
                obj[label] = [...obj[label], { ...json, type: 'table' }];
              } else {
                obj[label] = [{ ...json, type: 'table' }];
              }
              return obj;
            });
            return;
          }
          const { data, labels, total, sort, column_size, name } = json;
          if (total) paginatorRef.current?.setTotal(total);
          if (sort) setSort(sort);
          if (labels) setTableHeaders(labels);
          if (column_size) setColumnSize(column_size);
          setFetchedData(data);
        } else {
          const { data, labels, total, sort, column_size, name } = json;
          if (total) paginatorRef.current?.setTotal(total);
          if (sort) setSort(sort);
          if (labels) setTableHeaders(labels);
          if (column_size) setColumnSize(column_size);
          setFetchedData(data);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
        if (abortController.signal.aborted) {
          return;
        }
      });
  };

  const [metrics, setMetrics] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState(null);

  const fetchMetricsList = () => {
    setMetrics([]);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/wb/${pageValue}/analytics/${pageContent}/${pageContent === 'categorization' ? 'plots' : 'plots'
        }/metric-list`;
    } else {
      url = `${KonstantinMasterHttp}/wb/${pageValue}/analytics/${pageContent}/${pageContent === 'categorization' ? 'plots' : 'plots'
        }/metric-list`;
    }

    fetch(url, {
      method: 'GET',
      headers: headers,
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((error) => {
            throw new Error(`Error: ${error.default || response.statusText}`);
          });
        }
        return response.json();
      })
      .then((data) => {
        setMetrics(data);
        setSelectedMetric(data[0]?.value);
      })
      .catch((error) => {
        console.error('Error:', error.message);
      });
  };

  useEffect(() => {
    fetchMetricsList();
  }, [pageValue, pageContent]);

  const fetchChartData = async (
    path,
    abortController = new AbortController()
  ) => {
    if (!selectedMetric) return;
    if (selectedItems?.length < 1) return;
    setLoadChart(true);

    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/wb/${pageValue}/analytics/${pageContent}/${pageContent === 'categorization' ? 'plots' : 'plots'
        }`;
    } else {
      url = `${KonstantinMasterHttps}/wb/${pageValue}/analytics/${pageContent}/${pageContent === 'categorization' ? 'plots' : 'plots'
        }`;
    }

    let api_id_list;
    let mp_id_list;
    const [date_from, date_to] = date;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => el.value);
    }

    if (selectedMarketplace?.length > 0) {
      mp_id_list = selectedMarketplace?.map((el) => el.value);
    }

    let searchId;
    const id_arr = selectedItems?.map((el) => {
      if (el) {
        if (pageContent === 'by-product' && el?.['Товар']) {
          if (Array.isArray(el?.['Товар'])) {
            return el?.['Товар'][4];
          }
        }

        if (pageContent === 'by-category') {
          return el?.category_id;
        }

        if (pageContent === 'by-brand') {
          return el?.brand_id;
        }

        if (pageContent === 'categorization') {
          console.log(el);
          if (el?.['Категория']) {
            return el?.['Категория'];
          }
        }
      }
    });

    if (pageContent === 'by-product') {
      searchId = { nm_id_list: id_arr };
    }
    if (pageContent === 'by-category') {
      searchId = { category_id_list: id_arr };
    }
    if (pageContent === 'by-brand') {
      searchId = { brand_id_list: id_arr };
    }
    if (pageContent === 'categorization') {
      searchId = { primary_category_list: id_arr };
    }

    const body = JSON.stringify({
      mp_id_list,
      api_id_list,
      date_from: allPeriod
        ? new Date(0)
        : new Date(date_from + 10)?.toISOString().split('T')?.[0],
      date_to: new Date(date_to)?.toISOString().split('T')?.[0],
      metric_name: selectedMetric,
      ...searchId,
    });

    await fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setChartData(json);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadChart(false);
        if (abortController.signal.aborted) {
          setFetchedCharts([]);
          return;
        }
      });
  };

  useEffect(() => {
    resetChart();
  }, [
    pageContent,
    pageValue,
    selectedApi,
    selectedBrand,
    selectedCategory,
    date,
  ]);

  useEffect(() => {
    fetchChartData();
  }, [selectedMetric, selectedItems]);

  const fetchChartDataAdvanced = async (
    label,
    path,
    abortController = new AbortController()
  ) => {
    if (true) {
      setLoadChart(true);

      if (path) {
        const baseRoute =
          (pageContent === 'advanced') & (pageValue !== 'questions')
            ? KonstantinFeedbackHttps
            : KonstantinMasterHttps;
        const baseRouteHttp =
          (pageContent === 'advanced') & (pageValue !== 'questions')
            ? KonstantinFeedbackHttp
            : KonstantinMasterHttp;
        const mp =
          (pageContent === 'advanced') & (pageValue !== 'questions')
            ? 'wildberries'
            : 'wb';

        let url;
        if (!window.location.hostname.match('localhost')) {
          url = `${KonstantinMasterHttps}/wb/${pageValue}/analytics/${pageContent}/plots/${path}`;
        } else {
          url = `${KonstantinMasterHttps}/wb/${pageValue}/analytics/${pageContent}/plots/${path}`;
        }

        let api_id_list;
        let brand_id_list;
        let category_id_list;
        let mp_id_list;
        const [date_from, date_to] = date;

        if (selectedApi?.length > 0) {
          api_id_list = selectedApi?.map((el) => el.value);
        }
        if (selectedBrand?.length > 0) {
          brand_id_list = selectedBrand?.map((el) => el.value);
        }
        if (selectedCategory?.length > 0) {
          category_id_list = selectedCategory?.map((el) => el.value);
        }
        if (selectedMarketplace?.length > 0) {
          mp_id_list = selectedMarketplace?.map((el) => el.value);
        }

        const body = JSON.stringify({
          // positive: statusCup?.[path] === 'positive',
          mp_id_list,
          category_id_list,
          brand_id_list,
          api_id_list,
          date_from: allPeriod
            ? new Date(0)
            : new Date(date_from + 10)?.toISOString().split('T')?.[0],
          date_to: new Date(date_to)?.toISOString().split('T')?.[0],
        });

        await fetch(url, {
          body,
          method: 'POST',
          headers,
          signal: abortController.signal,
        })
          .then(async (res) => {
            if (res.ok) {
              return res.json();
            } else {
              const err = await res.json();
              throw new Error(JSON.stringify(err));
            }
          })
          .then((json) => {
            setFetchedCharts((prev) => {
              const obj = { ...prev };
              if (obj[label]) {
                obj[label] = [
                  ...obj[label],
                  { ...json, compType: 'chart', chartLabel: path },
                ];
              } else {
                obj[label] = [{ ...json, compType: 'chart', chartLabel: path }];
              }
              return obj;
            });
            return;
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setLoadChart(false);
            if (abortController.signal.aborted) {
              setFetchedCharts([]);
              return;
            }
          });
      }
    }
  };

  const fetchMetricData = (path, abortController = new AbortController()) => {
    setFetchedMetrics([]);
    setLoadMetrics(true);

    let url;
    // if (!window.location.hostname.match('localhost')) {
    //   url = `${KonstantinFeedbackHttps}/wildberries/${pageValue}/analytics/${pageContent}/metrics/${path}`;
    // } else {
    //   url = `${KonstantinFeedbackHttp}/wildberries/${pageValue}/analytics/${pageContent}/metrics/${path}`;
    // }

    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantinMasterHttps}/wb/${pageValue}/analytics/${pageValue != 'questions' ? pageContent + '/' : ''
        }metrics/${path}`;
    } else {
      url = `${KonstantinMasterHttp}/wb/${pageValue}/analytics/${pageValue != 'questions' ? pageContent + '/' : ''
        }metrics/${path}`;
    }

    let api_id_list;
    let brand_id_list;
    let category_id_list;
    const [date_from, date_to] = date;

    if (selectedApi?.length > 0) {
      api_id_list = selectedApi?.map((el) => el.value);
    }
    if (selectedBrand?.length > 0) {
      brand_id_list = selectedBrand?.map((el) => el.value);
    }
    if (selectedCategory?.length > 0) {
      category_id_list = selectedCategory?.map((el) => el.value);
    }

    const body = JSON.stringify({
      category_id_list,
      brand_id_list,
      api_id_list,
      date_from: allPeriod
        ? new Date(0)
        : new Date(date_from + 10)?.toISOString().split('T')?.[0],
      date_to: new Date(date_to)?.toISOString().split('T')?.[0],
    });

    fetch(url, {
      body,
      method: 'POST',
      headers,
      signal: abortController.signal,
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setFetchedMetrics((prev) => [...prev, json]);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoadMetrics(false);
        setLoading(false);
        if (abortController.signal.aborted) {
          setFetchedMetrics([]);
          return;
        }
      });
  };

  const fetchKeywordList = () => {
    if (selectedApi?.length > 0) {
      setLoading(true);

      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${KonstantinMasterHttps}/wb/${pageValue}/analytics/${pageContent}/keyword-list${'?order_param=' +
          currentParamKeywords +
          '&order_param_direction=' +
          sortKeywords +
          '&client_id=' +
          client_id
          }`;
      } else {
        url = `${KonstantinMasterHttp}/wb/${pageValue}/analytics/${pageContent}/keyword-list${'?order_param=' +
          currentParamKeywords +
          '&order_param_direction=' +
          sortKeywords +
          '&client_id=' +
          client_id
          }`;
      }

      let api_id_list;
      let brand_id_list;
      let category_id_list;
      const [date_from, date_to] = date;

      if (selectedApi?.length > 0) {
        api_id_list = selectedApi?.map((el) => el.value);
      }
      if (selectedBrand?.length > 0) {
        brand_id_list = selectedBrand?.map((el) => el.value);
      }
      if (selectedCategory?.length > 0) {
        category_id_list = selectedCategory?.map((el) => el.value);
      }

      const body = JSON.stringify({
        page: keywordsPage,
        limit: 20,
        category_id_list,
        brand_id_list,
        api_id_list,
        date_from: allPeriod
          ? new Date(0)
          : new Date(date_from + 10)?.toISOString().split('T')?.[0],
        date_to: new Date(date_to)?.toISOString().split('T')?.[0],
        positive: status === 'true',
        search_param: keywordsSearch,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then(({ items, positive, total }) => {
          if (keywordsPage > 1) {
            setKeywordsList((prev) => [
              ...prev,
              ...items?.map((el) => ({ ...el, positive })),
            ]);
          } else {
            setKeywordsList(items?.map((el) => ({ ...el, positive })));
          }
          setKeywordsTotal(total);
          setKeywrodsIsSearchLastPage(total < keywordsPage * 20);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchTagsList = () => {
    if (selectedApi?.length > 0) {
      setLoading(true);

      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${KonstantinMasterHttps}/wb/${pageValue}/analytics/${pageContent}/tag-list${'?order_param=' +
          currentParamTag +
          '&order_param_direction=' +
          sortTags +
          '&client_id=' +
          client_id
          }`;
      } else {
        url = `${KonstantinMasterHttp}/wb/${pageValue}/analytics/${pageContent}/tag-list${'?order_param=' +
          currentParamTag +
          '&order_param_direction=' +
          sortTags +
          '&client_id=' +
          client_id
          }`;
      }

      let mp_id_list;
      let api_id_list;
      let brand_id_list;
      let category_id_list;
      const [date_from, date_to] = date;

      if (selectedApi?.length > 0) {
        api_id_list = selectedApi?.map((el) => el.value);
      }
      if (selectedBrand?.length > 0) {
        brand_id_list = selectedBrand?.map((el) => el.value);
      }
      if (selectedCategory?.length > 0) {
        category_id_list = selectedCategory?.map((el) => el.value);
      }
      if (selectedMarketplace?.length > 0) {
        mp_id_list = selectedMarketplace?.map((el) => el.value);
      } else {
        mp_id_list = ['all'];
      }

      const body = JSON.stringify({
        mp_id_list,
        api_id_list,
        category_id_list,
        brand_id_list,
        page: tagsPage,
        limit: 20,
        date_from: allPeriod
          ? new Date(0)
          : new Date(date_from + 10)?.toISOString().split('T')?.[0],
        date_to: new Date(date_to)?.toISOString().split('T')?.[0],
        positive: status === 'true',
        keyword_list: checkedKeywords,
        search_param: tagsSearch,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          const { items, positive, total } = json;
          if (tagsPage > 1) {
            setTagsList((prev) => [
              ...prev,
              ...items?.map((el) => ({ ...el, positive })),
            ]);
          } else {
            setTagsList(items?.map((el) => ({ ...el, positive })));
          }
          setTagsTotal(total);
          setTagsIsSearchLastPage(total < tagsPage * 20);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const fetchFeedbacksList = () => {
    if (selectedApi?.length > 0) {
      setLoading(true);

      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${KonstantinMasterHttps}/wb/${pageValue}/analytics/${pageContent}/feedback-list${'?order_param=' +
          currentParamFeedbacks +
          '&order_param_direction=' +
          sortFeedbacks +
          '&client_id=' +
          client_id
          }`;
      } else {
        url = `${KonstantinMasterHttp}/wb/${pageValue}/analytics/${pageContent}/feedback-list${'?order_param=' +
          currentParamFeedbacks +
          '&order_param_direction=' +
          sortFeedbacks +
          '&client_id=' +
          client_id
          }`;
      }

      let mp_id_list;
      let api_id_list;
      let brand_id_list;
      let category_id_list;
      const [date_from, date_to] = date;

      if (selectedMarketplace?.length > 0) {
        mp_id_list = selectedMarketplace?.map((el) => el.value);
      } else {
        mp_id_list = ['all'];
      }

      if (selectedApi?.length > 0) {
        api_id_list = selectedApi?.map((el) => el.value);
      }
      if (selectedBrand?.length > 0) {
        brand_id_list = selectedBrand?.map((el) => el.value);
      }
      if (selectedCategory?.length > 0) {
        category_id_list = selectedCategory?.map((el) => el.value);
      }

      const body = JSON.stringify({
        mp_id_list,
        api_id_list,
        category_id_list,
        brand_id_list,
        page: feedbackPage,
        limit: 20,
        date_from: allPeriod
          ? new Date(0)
          : new Date(date_from + 10)?.toISOString().split('T')?.[0],
        date_to: new Date(date_to)?.toISOString().split('T')?.[0],
        positive: status === 'true',
        keyword_list: checkedKeywords,
        tag_list: checkedTags,
        search_param: feedbackSearch,
      });

      fetch(url, { body, method: 'POST', headers })
        .then(async (res) => {
          if (res.ok) {
            return res.json();
          } else {
            const err = await res.json();
            throw new Error(JSON.stringify(err));
          }
        })
        .then((json) => {
          const { items, total } = json;
          setFeedbackList(items);
          setFeedbacksTotal(total);
          setFeedbackIsSearchLastPage(total < feedbackPage * 20);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onRedirect = (e) => {
    const [dataObj, el, header] = e;
    const [name] = el;
    const { api_id } = dataObj || {};

    if (header === 'Артикул') {
    }

    if (header === 'Название') {
    }

    if (header === 'Категория') {
      navigate(`/work-with-review/feedbacks?category=${name}`);
    }

    if (header === 'Бренд') {
      navigate(`/work-with-review/feedbacks?brand=${name}`);
    }

    if (header === 'Магазин') {
      navigate(`/work-with-review/feedbacks?apiName=${name}&apiId=${api_id}`);
    }
  };

  const onScroll = (listRef, setPageHeight, setScrollTop) => {
    const { scrollTop, scrollHeight, clientHeight } = listRef.current;
    const bottomHeight = scrollHeight - clientHeight;
    if (bottomHeight) {
      setPageHeight(bottomHeight);
    }
    setScrollTop(scrollTop);
  };

  const fetchMetrics = (abortController = new AbortController()) => {
    setMetricsInfoLoading(true);
    const url = `${KonstantinMasterHttps}/wb/feedbacks/analytics/by-mood/metrics`;

    const body = JSON.stringify({
      api_id_list: selectedApi?.map((el) => el?.value),
      category_id_list: selectedCategory?.map((el) => el?.value),
      brand_id_list: selectedBrand?.map((el) => el?.value),
      mp_id_list: selectedMarketplace?.map((el) => el?.value),
      date_from: new Date(dateFrom)?.toISOString().split('T')?.[0],
      date_to: new Date(dateTo)?.toISOString().split('T')?.[0],
    });

    fetch(url, { body, method: 'POST', headers })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          const err = await res.json();
          throw new Error(JSON.stringify(err));
        }
      })
      .then((json) => {
        setMetricsInfo(json);
      })
      .catch((err) => {
        if (abortController.signal.aborted) return;
        console.error(err);
        setMetricsInfo({});
      })
      .finally(() => {
        setMetricsInfoLoading(false);
      });
  };

  const handleCheck = (data) => {
    setSelectedItems(data);
  };

  useEffect(() => {
    if (pageContent === 'by-mood') {
      if (selectedApi?.length > 0) {
        const abortController = new AbortController();
        fetchMetrics(abortController);
        return () => abortController.abort();
      } else {
        setMetricsInfo({});
      }
    } else {
      setMetricsInfo({});
    }
  }, [
    selectedSpace,
    selectedApi,
    pageContent,
    selectedBrand,
    selectedCategory,
    selectedMarketplace,
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const currentPageHeight = keywordsPageHeight;
      if (
        !keywordsIsSearchLastPage &&
        Math.ceil(keywordsPageScrollTop) >= currentPageHeight &&
        keywordsList?.length > 0
      ) {
        setKeywordsPage((prev) => prev + 1);
        return;
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [keywordsPageScrollTop, keywordsPageHeight, keywordsIsSearchLastPage]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const currentPageHeight = tagsPageHeight;
      if (
        !tagsIsSearchLastPage &&
        Math.ceil(tagsPageScrollTop) >= currentPageHeight &&
        tagsList?.length > 0
      ) {
        setTagsPage((prev) => prev + 1);
        return;
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [tagsPageScrollTop, tagsPageHeight, tagsIsSearchLastPage]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const currentPageHeight = feedbackPageHeight;
      if (
        !feedbackIsSearchLastPage &&
        Math.ceil(feedbackPageScrollTop) >= currentPageHeight &&
        feedbackList?.length > 0
      ) {
        setFeedbackPage((prev) => prev + 1);
        return;
      }
    }, 500);
    return () => clearTimeout(timeout);
  }, [feedbackPageScrollTop, feedbackPageHeight, feedbackIsSearchLastPage]);

  //логика для selected

  const mpParamRef = useRef([]);
  const categoryParamRef = useRef([]);
  const apiParamRef = useRef([]);
  const brandParamRef = useRef([]);

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    mpParamRef.current = params.get('mp_id')?.split(',') || [];
    categoryParamRef.current = params.get('category_id')?.split(',') || [];
    apiParamRef.current = params.get('api_id')?.split(',') || [];
    brandParamRef.current = params.get('brand_id')?.split(',') || [];

    if (mpParamRef.current.length > 0) {
      const mappedMarketplace = mpParamRef.current.map((mpId) =>
        options_prop.find((option) => String(option.value) === mpId)
      );
      setSelectedMarketplace(mappedMarketplace);
    }
  }, [location.search]);

  const apiFilter = () => {
    fetchFilters({
      filterName: 'fq_api_ids',
      selectedFilters: {
        selected_id: apiParamRef.current,
        marketplace: selectedMarketplace,
      },
      setFilterData: setApiData,
      setSelectedFilter: setSelectedApi,
      page: apiPage,
      searchParam: apiQuery,
      append: apiPage > 1,
    });
  };

  const categoryFilter = () => {
    fetchFilters({
      filterName:
        pageValue === 'feedbacks'
          ? 'feedbacks_categories'
          : 'questions_categories',
      selectedFilters: {
        selected_id: categoryParamRef.current,
        api: selectedApi,
        brand: selectedBrand,
        marketplace: selectedMarketplace,
        dateFrom,
        dateTo,
      },
      setFilterData: setCategoryData,
      setSelectedFilter: setSelectedCategory,
      page: categoryPage,
      searchParam: categoryQuery,
      append: categoryPage > 1,
    });
  };

  const brandFilter = () => {
    fetchFilters({
      filterName:
        pageValue === 'feedbacks' ? 'feedbacks_brands' : 'questions_brands',
      selectedFilters: {
        selected_id: brandParamRef.current,
        api: selectedApi,
        category: selectedCategory,
        marketplace: selectedMarketplace,
        dateFrom,
        dateTo,
      },
      setFilterData: setBrandData,
      setSelectedFilter: setSelectedBrand,
      page: brandPage,
      searchParam: brandQuery,
      append: brandPage > 1,
    });
  };

  useEffect(() => {
    apiFilter();
  }, [apiPage, apiQuery, pageValue]);

  useEffect(() => {
    categoryFilter();
  }, [categoryPage, categoryQuery, pageValue]);

  useEffect(() => {
    brandFilter();
  }, [brandPage, brandQuery, pageValue]);

  useEffect(() => {
    // setPageValue(activeTab);
    // setPageContent(searchParam?.get('content'));
    setStatusCup({});
    setCheckedKeywords([]);
    setCheckedTags([]);
    //   setFetchedData([]);
    setKeywordsList([]);
    setTagsList([]);
    setFeedbackList([]);
    setKeywordsPage(1);
    setTagsPage(1);
    setFeedbackPage(1);
  }, [activeTab, searchParam, status, date]);

  useEffect(() => {
    setCheckedTags([]);
  }, [checkedKeywords]);

  useEffect(() => {
    setFetchedMetrics([]);
    setFetchedCharts([]);
    setKeywordsList([]);
    setTagsList([]);
    setFeedbackList([]);
    setCheckedKeywords([]);
    setCheckedTags([]);
    const abortController = new AbortController();

    resetTable();
    if (
      selectedApi.length > 0 &&
      selectedBrand.length > 0 &&
      selectedCategory.length > 0
    ) {
      if (pageContent === 'advanced') {
        Object.keys(advanced_names)
          ?.filter((el) => el?.indexOf(`${pageValue}_`) !== -1)
          ?.map(async (key) => {
            await advanced_names?.[key]?.forEach(async ({ path, type }) => {
              if (type === 'chart') {
                await fetchChartDataAdvanced(key, path, abortController);
              } else {
                await fetchTableData([], path, key, abortController);
              }
            });
          });
      } else {
        fetchTableData([], true);

        if (pageValue != 'questions') {
          metrics_names_feedbacks[pageContent]?.map(({ path }) =>
            fetchMetricData(path, abortController)
          );
        } else {
          metrics_names_questions?.map((el) =>
            fetchMetricData(el, abortController)
          );
        }
      }
    }

    if (pageContent === 'by-mood') {
      fetchKeywordList();
    }
    return () => abortController.abort();
  }, [
    selectedSpace,
    // selectedApi,
    // selectedMarketplace,
    // selectedBrand,
    // selectedCategory,
    pageValue,
    status,
    pageContent,
    date,
    allPeriod,
  ]);

  const [isTableRequest, setIsTableRequest] = useState(false);

  useEffect(() => {
    setFetchedMetrics([]);
    setFetchedCharts([]);
    setKeywordsList([]);
    setTagsList([]);
    setFeedbackList([]);
    setCheckedKeywords([]);
    setCheckedTags([]);

    resetTable();
    const abortController = new AbortController();

    if (isTableRequest) {
      if (pageContent === 'advanced') {
        Object.keys(advanced_names)
          ?.filter((el) => el?.indexOf(`${pageValue}_`) !== -1)
          ?.map(async (key) => {
            await advanced_names?.[key]?.forEach(async ({ path, type }) => {
              if (type === 'chart') {
                await fetchChartDataAdvanced(key, path, abortController);
              } else {
                await fetchTableData([], path, key, abortController);
              }
            });
          });
      } else {
        fetchTableData([], true);

        if (pageValue != 'questions') {
          metrics_names_feedbacks[pageContent]?.map(({ path }) =>
            fetchMetricData(path, abortController)
          );
        } else {
          metrics_names_questions?.map((el) =>
            fetchMetricData(el, abortController)
          );
        }
      }
    }
    setIsTableRequest(false);
  }, [isTableRequest]);

  useEffect(() => {
    fetchKeywordList();
    //  setCheckedTags([])
  }, [
    selectedSpace,
    keywordsPage,
    currentParamKeywords,
    sortKeywords,
    keywordsSearch,
  ]);

  useEffect(() => {
    setFetchedMetrics([]);
  }, [
    selectedSpace,
    pageValue,
    selectedApi,
    selectedBrand,
    selectedCategory,
    selectedMarketplace,
  ]);

  useEffect(() => {
    if (pageContent == 'by-mood') {
      fetchTagsList([], true);
      // fetchKeywordList()
      // fetchFeedbacksList()
    }
  }, [
    selectedSpace,
    checkedKeywords,
    tagsPage,
    currentParamTag,
    sortTags,
    tagsSearch,
  ]);

  useEffect(() => {
    if (pageContent == 'by-mood') {
      fetchFeedbacksList();
    }
  }, [
    selectedSpace,
    checkedTags,
    currentParamFeedbacks,
    sortFeedbacks,
    feedbackSearch,
  ]);

  useEffect(() => {
    if (pageContent !== 'advanced' && Object.keys(statusCup)?.length > 0) {
      setFetchedCharts({});
      const abortController = new AbortController();
      charts_names[pageValue + pageContent]?.map(({ label, path }) =>
        fetchChartDataAdvanced(label, path, abortController)
      );
      return () => abortController.abort();
    }
  }, [selectedSpace, statusCup]);

  useEffect(() => {
    // fetchConnectionStatus()
  }, [isModalActive]);

  console.log(pageValue)

  return (
    <>
      {isModal && (
        <ModalReview
          pageValue={pageValue}
          questData={questData}
          setQuestData={(e) => setQuestData(e)}
          setIsModal={(e) => setIsModal(e)}
        />
      )}

      <div className="analitics-review">
        <div className="analitics-review__content">
          <header className="analitics-review__header">
            <h1 className="title_main">
              Аналитика по {pageValue == 'analitics-review-feedbacks' ? 'отзывам' : 'вопросам'}
            </h1>
            <button
              className="btn_info"
              onClick={(e) => {
                // const queryParams = new URLSearchParams();
                // queryParams.set('content', pageContent);
                // queryParams.set('level-id', '67');
                // queryParams.set('article-id', '735');

                // // Изменяем URL
                // navigate(`?${queryParams.toString()}`);
                console.log(searchParam);
                setSearchParam({
                  content: pageContent,
                  'level-id': 67,
                  'article-id': 735,
                });
                setIsModalKnowledgeBase(true);
              }}
            >
              Что это такое? <img src={info_icon} />
            </button>
          </header>

          <div className="analitics-review__toolbar-top">
            <FilterContainer
              onReset={(e) => {
                if (isNotOneAccount !== 'done') {
                  setSelectedApi([]);
                  setSelectedCategory([]);
                  setSelectedBrand([]);
                }
              }}
            >
              <DropDownSelector
                options_prop={options_prop}
                state={selectedMarketplace}
                setState={(e) => setSelectedMarketplace(e)}
                // disabled={isNotOneAccount !== 'done' || loading}
                placeholder="Маркетплейс"
                defaultValue={[{ label: 'Все', value: 'all' }]}
                multi
                onBlur={() => {
                  apiFilter();
                  categoryFilter();
                  brandFilter();
                  setIsTableRequest(true);
                }}
              />

              {/* <DropDownSelector
                options_prop={apiData}
                state={selectedApi}
                setState={(e) => setSelectedApi(e)}
                className="connections_page_selector"
                placeholder="Магазин"
                fetchCallback={(e) => setApiPage(e)}
                setInputChange={(e) => setApiQuery(e)}
                // disabled={isNotOneAccount !== 'done'}
                multi
                onBlur={() => {
                  categoryFilter();
                  brandFilter();
                  setIsTableRequest(true);
                }}
              />

              <DropDownSelector
                state={selectedCategory}
                options_prop={categoryData}
                setState={(e) => setSelectedCategory(e)}
                className="connections_page_selector"
                placeholder="Категория"
                fetchCallback={(e) => setCategoryPage(e)}
                setInputChange={(e) => setCategoryQuery(e)}
                defaultValue={[{ label: 'Все', value: 'all' }]}
                // disabled={isNotOneAccount !== 'done'}
                multi
                onBlur={() => {
                  brandFilter();
                  setIsTableRequest(true);
                }}
              />
              <DropDownSelector
                state={selectedBrand}
                options_prop={brandData}
                setState={(e) => setSelectedBrand(e)}
                className="connections_page_selector"
                placeholder="Бренд"
                fetchCallback={(e) => setBrandPage(e)}
                setInputChange={(e) => setBrandQuery(e)}
                defaultValue={[{ label: 'Все', value: 'all' }]}
                // disabled={isNotOneAccount !== 'done'}
                onBlur={() => {
                  setIsTableRequest(true);
                }}
                multi
              /> */}

              <DropDownSelector
                style={{ maxWidth: '210px', minWidth: '210px' }}
                options_prop={apiData}
                state={selectedApi}
                setState={(e) => setSelectedApi(e)}
                placeholder="Магазин"
                fetchCallback={(e) => setApiPage(e)}
                setInputChange={(e) => setApiQuery(e)}
                defaultValue={[{ label: 'Все', value: 'all' }]}
                multi
                onBlur={() => {
                  categoryFilter();
                  brandFilter();
                  setIsTableRequest(true);
                }}
              />

              <DropDownSelector
                style={{ maxWidth: '210px', minWidth: '210px' }}
                options_prop={categoryData}
                state={selectedCategory}
                setState={(e) => setSelectedCategory(e)}
                placeholder="Категория"
                fetchCallback={(e) => setCategoryPage(e)}
                setInputChange={(e) => setCategoryQuery(e)}
                defaultValue={[{ label: 'Все', value: 'all' }]}
                multi
                onBlur={() => {
                  brandFilter();
                  setIsTableRequest(true);
                }}
              />

              <DropDownSelector
                style={{ maxWidth: '210px', minWidth: '210px' }}
                options_prop={brandData}
                state={selectedBrand}
                setState={(e) => setSelectedBrand(e)}
                placeholder="Бренд"
                fetchCallback={(e) => setBrandPage(e)}
                setInputChange={(e) => setBrandQuery(e)}
                defaultValue={[{ label: 'Все', value: 'all' }]}
                multi
                onBlur={() => {
                  setIsTableRequest(true);
                }}
              />
            </FilterContainer>

            <RangeBox
              allPeriod={allPeriod}
              setAllPeriod={(e) => setAllPeriod(e)}
              setDate={(e) => setDate(e)}
              disabled={isNotOneAccount !== 'done'}
            />
          </div>
          <Tabs
            query={location?.search}
            disabled={loadChart || loadMetrics}
            tabs={btn_tab}
            pageValue={pageContent}
            spanTab
            setPageValue={(e) => {
              // setSearchParam({ content: e });
              setPageContent(e);
            }}
          />

          {isNotOneAccount === 'waiting' ? (
            <NoDataContent
              img={banner_no_data}
              title={<h3>Подключение создано</h3>}
              description={
                <p style={{ textAlign: 'center' }}>
                  Сбор и обработка информации{' '}
                  <span style={{ fontWeight: '600', color: '#68727D' }}>
                    займет до 24 часов.
                  </span>{' '}
                  <br />
                  Пока мы настраиваем подключение, вы можете ознакомиться с
                  инструкцией по разделу.
                </p>
              }
            />
          ) : isNotOneAccount === 'none' ? (
            <NoDataContent
              img={banner_no_data}
              title="Нет активных подключений"
              description="Создайте подключение чтобы пользоваться всеми инструментами сервиса"
              child={
                <ButtonBasic
                  green
                  onClick={(e) => {
                    openConnectionModalById([1, 3]);
                  }}
                  text="Новое подключение"
                  size="56px"
                />
              }
            />
          ) : (
            <>
              {(pageContent === 'by-product' ||
                pageContent === 'by-category' ||
                pageContent === 'by-brand' ||
                pageContent === 'categorization') && (
                  <DropContainer
                    name={'Динамика показателей за выбранный период'}
                    showDropBox={showDropBox}
                    setShowDropBox={(e) => setShowDropBox(e)}
                  >
                    {(pageContent === 'by-product' ||
                      pageContent === 'by-category' ||
                      pageContent === 'by-brand' ||
                      pageContent === 'categorization') && (
                        <div className="analitics-review__chart-and-metrics">
                          <div className="analitics-review__chart-group">
                            <LineChart
                              {...chartData}
                              legend={metrics}
                              selectedMetric={selectedMetric}
                              setSelectedMetric={setSelectedMetric}
                              metricsList={metrics}
                            />
                          </div>

                          {(pageContent === 'by-product' ||
                            pageContent === 'by-category' ||
                            pageContent === 'by-brand' ||
                            pageContent === 'categorization') && (
                              <div className="analitics-review__metrics-group">
                                {fetchedMetrics?.length > 0
                                  ? fetchedMetrics?.map((el) => {
                                    const { labels, data } = el;
                                    return (
                                      <MetricsItem
                                        key={JSON.stringify(el)}
                                        data={data}
                                        {...labels}
                                      />
                                    );
                                  })
                                  : [...Array(6)]?.map((e, i) => {
                                    return (
                                      <CardStatistics key={i} title="" value="" />
                                    );
                                  })}
                              </div>
                            )}
                        </div>
                      )}

                    {pageContent === 'mood' && (
                      <>
                        <div className="chart-group">
                          <div style={{ width: '33%', minWidth: '33%' }}>
                            <DoughnutChart
                              nameChart="Тональность отзывов"
                              maxHeight="200px"
                            />
                          </div>

                          <BarCharts
                            direction="x"
                            legendPosition="bottom"
                            datasets_prop={[]}
                            labels_prop={[]}
                            loading={null}
                            nameChart="Тональность отзывов по дням"
                            maxHeight="200px"
                          />
                        </div>

                        <div className="chart-group">
                          <BarCharts
                            direction="Y"
                            legendPosition="bottom"
                            datasets_prop={[]}
                            labels_prop={[]}
                            loading={null}
                            nameChart="Заголовок графика"
                            maxHeight="200px"
                          />

                          <BarCharts
                            direction="Y"
                            legendPosition="bottom"
                            datasets_prop={[]}
                            labels_prop={[]}
                            loading={null}
                            nameChart="Заголовок графика"
                            maxHeight="200px"
                          />

                          <BarCharts
                            direction="Y"
                            legendPosition="bottom"
                            datasets_prop={[]}
                            labels_prop={[]}
                            loading={null}
                            nameChart="Топ 5 позитивных брендов"
                            maxHeight="200px"
                          />
                        </div>

                        <div className="chart-group">
                          <BarCharts
                            direction="Y"
                            legendPosition="bottom"
                            datasets_prop={[]}
                            labels_prop={[]}
                            loading={null}
                            nameChart="Топ 5 негативных категорий"
                            maxHeight="200px"
                          />

                          <BarCharts
                            direction="Y"
                            legendPosition="bottom"
                            datasets_prop={[]}
                            labels_prop={[]}
                            loading={null}
                            nameChart="Топ 5 негативных категорий"
                            maxHeight="200px"
                          />

                          <BarCharts
                            direction="Y"
                            legendPosition="bottom"
                            datasets_prop={[
                              [12, 13],
                              [11, 15],
                            ]}
                            labels_prop={[]}
                            loading={null}
                            nameChart="Топ 5 негвтивных брендов"
                          />
                        </div>
                      </>
                    )}
                  </DropContainer>
                )}

              {pageContent === 'by-mood' && (
                <section className="review-group">
                  <div className="review-group__top-content">
                    <div className="card-group">
                      <div className="card-item">
                        {metricsInfoLoading ? (
                          'Загрузка'
                        ) : (
                          <>
                            <p className="text">Количество отзывов</p>
                            <p className="text_sum">
                              {metricsInfo?.feedback_count
                                ? metricsInfo?.feedback_count
                                : '-'}
                            </p>
                          </>
                        )}
                      </div>

                      <div className="card-item">
                        {metricsInfoLoading ? (
                          ''
                        ) : (
                          <>
                            <p className="text">Отзывов с текстом</p>
                            <p className="text_sum">
                              {metricsInfo?.feedback_text?.length > 0
                                ? `${metricsInfo?.feedback_text?.[0]} (${metricsInfo?.feedback_text?.[1]})`
                                : '-'}
                            </p>
                          </>
                        )}
                      </div>

                      <div className="card-item">
                        <p className="text">Популярный позитивный тег</p>

                        <div className="tag-item">
                          {metricsInfoLoading ? (
                            'Загрузка'
                          ) : (
                            <>
                              <img src={tag_icon} />
                              <p className="text">
                                {metricsInfo?.keyword_pos
                                  ? metricsInfo?.keyword_pos
                                  : '-'}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="card-item">
                        <p className="text">Популярный негативный тег</p>

                        <div className="tag-item">
                          {metricsInfoLoading ? (
                            'Загрузка'
                          ) : (
                            <>
                              <img src={tag_icon} />
                              <p className="text">
                                {metricsInfo?.keyword_neg
                                  ? metricsInfo?.keyword_neg
                                  : '-'}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="tone-feedback">
                      <h4 className="title">Тональность отзывов</h4>
                      <div className="tone-feedback__content">
                        {metricsInfoLoading ? (
                          'Загрузка'
                        ) : (
                          <div className="dynamic-tone">
                            <div className="dynamic-tone__top">
                              <p className="text">Позитивные</p>
                              <p className="text_percent">
                                {metricsInfo?.perc_positive?.length > 0
                                  ? `${metricsInfo?.perc_positive?.[0]} (${metricsInfo?.perc_positive?.[1]}%)`
                                  : '-'}
                              </p>
                            </div>
                          </div>
                        )}

                        {metricsInfoLoading ? (
                          'Загрузка'
                        ) : (
                          <div className="dynamic-tone">
                            <div className="dynamic-tone__top">
                              <p className="text">Негативные</p>
                              <p className="text_percent">
                                {metricsInfo?.perc_negative?.length > 0
                                  ? `${metricsInfo?.perc_negative?.[0]} (${metricsInfo?.perc_negative?.[1]}%)`
                                  : '-'}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="tone-feedback__content">
                        <div className="dynamic-tone" style={{ width: '100%' }}>
                          <div className="dynamic_positive">
                            <div
                              className="dynamic_bkg"
                              style={{
                                width: `${metricsInfo?.perc_positive?.[1]}%`,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <TabsGreyBtn
                    btns={btns}
                    currentBtn={status}
                    setCurrentBtn={(e) => setStatus(e)}
                    checkValue={true}
                  />

                  <div className="review-group__content">
                    <div className="review-list">
                      <div
                        className="review-list__header"
                        style={{
                          flexDirection: 'column',
                          maxHeight: 'unset',
                          alignItems: 'flex-start',
                        }}
                      >
                        <div className="sort-group">
                          <div className="sort-group-btn-group">
                            <button
                              className="btn-sort"
                              onClick={(e) => {
                                setKeywordsPage(1);
                                setSortKeywords(
                                  sortKeywords == 'DESC' ? 'ASC' : 'DESC'
                                );
                                setCurrentParamKeywords('tag_count');
                              }}
                            >
                              <img src={sort_icon} /> Теги
                            </button>
                            <button
                              className="btn-sort"
                              onClick={(e) => {
                                setKeywordsPage(1);
                                setSortKeywords(
                                  sortKeywords == 'DESC' ? 'ASC' : 'DESC'
                                );
                                setCurrentParamKeywords('feedback_count');
                              }}
                            >
                              <img src={sort_icon} /> Отзывы
                            </button>
                          </div>

                          <p className="text_sum">
                            Всего ключ. слов: <span>{keywordsTotal}</span>
                          </p>
                        </div>

                        <InputDynamicWidth
                          position="left"
                          maxWidth="100%"
                          isOpen={true}
                          onChange={(value) => setKeywordsSearch(value)}
                          placeholder="Ключевая фраза"
                        />
                      </div>

                      {/* <header className='review-list__header-bottom'>
                                                    <h3 className='title'>Ключевая фраза</h3>
                                                </header> */}

                      <div
                        className="review-list__content"
                        ref={keywordsRef}
                        onScroll={(e) =>
                          onScroll(
                            keywordsRef,
                            setKeywordsPageHeight,
                            setKeywordsPageScrollTop
                          )
                        }
                      >
                        {keywordsList?.map((el) => {
                          return (
                            <ReviewLlist
                              {...el}
                              checked={checkedKeywords}
                              keyword_list={keywordsList}
                              setChecked={(e) => setCheckedKeywords(e)}
                            />
                          );
                        })}
                      </div>
                    </div>

                    <div className="review-list">
                      <div
                        className="review-list__header"
                        style={{
                          flexDirection: 'column',
                          maxHeight: 'unset',
                          alignItems: 'flex-start',
                        }}
                      >
                        <div className="sort-group">
                          <div className="sort-group-btn-group">
                            <button
                              className="btn-sort"
                              onClick={(e) => {
                                setTagsPage(1);
                                setSortTags(
                                  sortTags == 'DESC' ? 'ASC' : 'DESC'
                                );
                                setCurrentParamTag('keyword');
                              }}
                            >
                              <img src={sort_icon} /> Теги
                            </button>

                            <button
                              className="btn-sort"
                              onClick={(e) => {
                                setTagsPage(1);
                                setSortTags(
                                  sortTags == 'DESC' ? 'ASC' : 'DESC'
                                );
                                setCurrentParamTag('tag');
                              }}
                            >
                              <img src={sort_icon} />
                              Группа тегов
                            </button>

                            <button
                              className="btn-sort"
                              onClick={(e) => {
                                setTagsPage(1);
                                setSortTags(
                                  sortTags == 'DESC' ? 'ASC' : 'DESC'
                                );
                                setCurrentParamTag('feedback_count');
                              }}
                            >
                              <img src={sort_icon} />
                              Кол-во упоминаний
                            </button>
                          </div>

                          <p className="text_sum">
                            Всего тегов: <span>{tagsTotal}</span>
                          </p>
                        </div>

                        <InputDynamicWidth
                          position="left"
                          maxWidth="100%"
                          isOpen={true}
                          onChange={(value) => setTagsSearch(value)}
                          placeholder="Тег"
                        />
                      </div>

                      {/* <header className='review-list__header-bottom'>
                                                    <h3 className='title'>Фраза</h3>
                                                    <h3 className='title'>Тег</h3>
                                                </header> */}

                      <div
                        className="review-list__content"
                        ref={tagsRef}
                        onScroll={(e) =>
                          onScroll(
                            tagsRef,
                            setTagsPageHeight,
                            setTagsPageScrollTop
                          )
                        }
                      >
                        {tagsList?.map((el) => {
                          return (
                            <ReviewLlist
                              {...el}
                              checked={checkedTags}
                              setChecked={(e) => setCheckedTags(e)}
                              tag_list={tagsList}
                              tags
                            />
                          );
                        })}
                      </div>
                    </div>

                    <div className="product-content">
                      <div
                        className="product-content__header"
                        style={{
                          flexDirection: 'column',
                          maxHeight: 'unset',
                          alignItems: 'flex-start',
                        }}
                      >
                        <div className="sort-group">
                          <div className="sort-group-btn-group">
                            <button
                              className="btn-sort"
                              onClick={(e) => {
                                setFeedbackPage(1);
                                setSortFeedbacks(
                                  sortFeedbacks == 'DESC' ? 'ASC' : 'DESC'
                                );
                                setCurrentParamFeedbacks('Дата');
                              }}
                            >
                              <img src={sort_icon} />
                              Дата
                            </button>

                            <button
                              className="btn-sort"
                              onClick={(e) => {
                                setFeedbackPage(1);
                                setSortFeedbacks(
                                  sortFeedbacks == 'DESC' ? 'ASC' : 'DESC'
                                );
                                setCurrentParamFeedbacks('Оценка');
                              }}
                            >
                              <img src={sort_icon} /> Оценка
                            </button>
                          </div>

                          <p className="text_sum">
                            Всего отзывов: <span>{feedbacksTotal}</span>
                          </p>
                        </div>

                        <InputDynamicWidth
                          position="left"
                          maxWidth="100%"
                          isOpen={true}
                          onChange={(value) => setFeedbackSearch(value)}
                          placeholder="Товар"
                        />
                      </div>

                      {/* <header className='review-list__header-bottom' style={{ border: 'none' }}></header> */}

                      <div
                        className="product-content__content"
                        ref={feedbacksRef}
                        onScroll={(e) =>
                          onScroll(
                            feedbacksRef,
                            setFeedbackPageHeight,
                            setFeedbackPageScrollTop
                          )
                        }
                      >
                        {feedbackList?.length ? (
                          feedbackList?.map((el) => {
                            const { tag_list } = el;
                            return (
                              <CardMessageReviewChat
                                {...el}
                                tag={
                                  Array.isArray(tag_list)
                                    ? tag_list?.join(', ')
                                    : tag_list
                                }
                                setIsModal={(e) => {
                                  setIsModal(e);
                                  setQuestData(el);
                                }}
                              />
                            );
                          })
                        ) : (
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            список пуст
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </>
          )}
        </div>

        {pageContent === 'advanced' && (
          <div className="groop-container">
            {Object.keys(fetchedCharts)?.length < 1 &&
              pageValue === 'feedbacks' && (
                <>
                  <LineChart
                    nameChart="Взаимосвязь сред. продаж от кол-ва отзывов (каждый день)"
                    {...chartData}
                  />

                  <p className="groop-container__text">
                    На данных графиках можно увидеть влияние отзывов на средние
                    просмотры. На точечном графике можно увидеть, где больше
                    всего расположены точки, там и существует взамиосвязь
                    средних просмотров от отзывов. Также можно увидеть, при
                    каком количестве отзывов увеличивается уровень просмотров.
                    Линейный график показывает измение показателей по дням.
                    Внизу (или справа) представлена сводная таблица по изменеию
                    средних просмотров при изменении отзывов:
                  </p>

                  <div className="groop-container__chart-and-table">
                    <LineChart
                      nameChart="Взаимосвязь сред. продаж от кол-ва отзывов"
                      {...chartData}
                    />
                    <TableBox maxHeight="340px" />
                  </div>

                  <div className="groop-container__charts">
                    <LineChart
                      nameChart="Взаимосвязь сред. продаж от ср. рейтинга"
                      {...chartData}
                    />
                    <LineChart
                      nameChart="Взаимосвязь сред. продаж от ср. рейтинга (каждый день)"
                      {...chartData}
                    />
                  </div>

                  <p className="groop-container__text">
                    На данном графике можно увидеть влияние отрицательных оценок
                    на средние продажи. Если при увеличении отрицательных
                    отзывов в какой-то момент средние продажи уменьшаются,
                    возможно есть влияние отрицательных оценок на сред. продажи.
                    Стоит обратить внимание на данные оценки и написать ответ по
                    отзыву для разъяснения.
                  </p>

                  <div className="groop-container__tables">
                    <TableBox maxHeight="320px" />
                    <TableBox maxHeight="320px" />
                  </div>

                  <LineChart
                    nameChart="Взаимосвязь сред. просмотров от отриц. оценок (каждый день)"
                    {...chartData}
                  />
                </>
              )}

            {Object.keys(fetchedCharts)?.length < 1 &&
              pageValue !== 'feedbacks' && (
                <>
                  <LineChart
                    nameChart="Взаимовсязь сред.продаж от к-ва вопросов (каждый день)"
                    {...chartData}
                  />

                  <p className="groop-container__text">
                    На данных графиках можно увидеть влияние вопросов на средние
                    продажи. На точечном графике можно увидеть, где больше всего
                    расположены точки, там и существует взамиосвязь средних
                    продаж от вопросов. Также можно увидеть, при каком
                    количестве вопросов увеличивается или уменьшается уровень
                    продаж. Линейный график показывает измение показателей по
                    дням.
                    <br />
                    Внизу (справа) представлена сводная таблица по изменеию
                    средних продаж при изменении вопросов:
                  </p>

                  <div className="groop-container__chart-and-table">
                    <LineChart
                      nameChart="Взаимовсязь сред.продаж от к-ва вопросов"
                      {...chartData}
                    />
                    <TableBox maxHeight="340px" />
                  </div>

                  <LineChart
                    nameChart="Взаимовсязь сред.продаж от сред.времени ответа (каждый день)"
                    {...chartData}
                  />

                  <p className="groop-container__text">
                    На данных графиках можно увидеть влияние времени ответа на
                    средние продажи. На точечном графике можно увидеть, где
                    больше всего расположены точки, там и существует взамиосвязь
                    средних продаж от времени ответа на вопрос. Также можно
                    увидеть, при каком ср.времени ответа увеличивается уровень
                    продаж. Линейный график показывает измение показателей по
                    дням.
                    <br /> Внизу (справа) представлена сводная таблица по
                    изменеию средних продаж при изменении времени ответа на
                    вопрос:
                  </p>

                  <div className="groop-container__charts">
                    <LineChart
                      nameChart="Взаимовсязь сред.продаж от сред.времени ответа"
                      {...chartData}
                    />

                    <TableBox maxHeight="320px" />
                  </div>
                </>
              )}
            {Object.keys(fetchedCharts)?.length > 0 &&
              Object.keys(fetchedCharts)?.map((key, ind) => {
                return (
                  <>
                    <div className="chart-and-table-content-group">
                      <div className="charts-and-table-content">
                        {fetchedCharts?.[key]?.map((el) => {
                          const { compType } = el;
                          if (compType === 'chart') {
                            try {
                              const {
                                type,
                                datasets: { data, label },
                                labels,
                                name,
                              } = el;
                              console.log(el);
                              return (
                                <>
                                  <LineChart
                                    {...el}
                                    datasets_prop={data}
                                    labels_prop={labels}
                                    data_name={label?.length > 0 ? label : ['']}
                                    loading={null}
                                    direction="x"
                                    type={[type]}
                                    legendPosition="bottom"
                                    nameChart={name}
                                    maxHeight="300px"
                                  />
                                  {name == 'Влияние отзывов на продажи' && (
                                    <p className="groop-container__text">
                                      {tooltips[0]}
                                    </p>
                                  )}

                                  {name == 'Влияние отзывов на просмотры' && (
                                    <p className="groop-container__text">
                                      {tooltips[1]}
                                    </p>
                                  )}
                                </>
                              );
                            } catch (error) {
                              return (
                                <BarCharts
                                  datasets_prop={[]}
                                  labels_prop={[]}
                                  data_name={''}
                                  loading={null}
                                  legendPosition="bottom"
                                  maxHeight="300px"
                                />
                              );
                            }
                          } else {
                            try {
                              const { data, labels, name } = el;
                              return (
                                <>
                                  <div
                                    style={{
                                      width: '100%',

                                      maxWidth:
                                        name ==
                                          'ТОП-5 товаров, по которым были отрицательные оценки' ||
                                          name ==
                                          'ТОП-5 товаров, по которым долгое время не было отзывов'
                                          ? 'calc(50% - 4px)'
                                          : 'calc(40% - 12px)',
                                    }}
                                  >
                                    <TableBox
                                      {...el}
                                      hideExport
                                      hideColumn
                                      fetchedData={data}
                                      headers={labels}
                                      paginator={false}
                                      maxHeight="320px"
                                      child={
                                        <p className="title_table">{name}</p>
                                      }
                                    />
                                  </div>
                                  {name ==
                                    'Изменение просмотров и продаж при изменении рейтинга' && (
                                      <p className="groop-container__text">
                                        {tooltips[2]}
                                      </p>
                                    )}

                                  {name ==
                                    'Изменение продаж при изменении кол-ва вопросов' && (
                                      <p className="groop-container__text">
                                        На данных графиках можно увидеть влияние
                                        вопросов на средние продажи. На точечном
                                        графике можно увидеть, где больше всего
                                        расположены точки, там и существует
                                        взамиосвязь средних продаж от вопросов.
                                        Также можно увидеть, при каком количестве
                                        вопросов увеличивается или уменьшается
                                        уровень продаж. Линейный график показывает
                                        измение показателей по дням.
                                        <br /> Внизу (справа) представлена сводная
                                        таблица по изменеию средних продаж при
                                        изменении вопросов:
                                      </p>
                                    )}

                                  {name ==
                                    'Изменение продаж при изменении времени ответа' && (
                                      <p className="groop-container__text">
                                        На данных графиках можно увидеть влияние
                                        времени ответа на средние продажи. На
                                        точечном графике можно увидеть, где больше
                                        всего расположены точки, там и существует
                                        взамиосвязь средних продаж от времени
                                        ответа на вопрос. Также можно увидеть, при
                                        каком ср.времени ответа увеличивается
                                        уровень продаж. Линейный график показывает
                                        измение показателей по дням. <br />
                                        Внизу (справа) представлена сводная
                                        таблица по изменеию средних продаж при
                                        изменении времени ответа на вопрос:
                                      </p>
                                    )}
                                </>
                              );
                            } catch (error) {
                              return <TableBox maxHeight="320px" />;
                            }
                          }
                        })}
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        )}

        {pageContent !== 'advanced' &&
          pageContent !== 'by-mood' &&
          pageContent !== 'analytics' && (
            <div id="table" style={{ width: '100%' }}>
              <TableBox
                sort={sort}
                onRedirectInfo
                paginator={true}
                loading={loading}
                ref={paginatorRef}
                headers={tableHeaders}
                onRedirect={onRedirect}
                columnSize={column_size}
                fetchedData={fetchedData}
                fetchCallback={fetchTableData}
                onCheck={(e) => handleCheck(e)}
                noDataText="Выберите группу тегов выше, чтобы увидеть список товаров по ним"
                onUncheckAllDependence={[
                  pageValue,
                  pageContent,
                  selectedApi,
                  selectedBrand,
                  selectedCategory,
                  selectedMarketplace,
                  date,
                ]}
              />
            </div>
          )}

        {pageContent === 'analytics' && (
          <div>
            {pageValue === 'feedbacks' ? (
              <AdvancedAnalytics
                date={date}
                selectedBrand={selectedBrand}
                selectedCategory={selectedCategory}
                selectedApi={selectedApi}
                selectedMarketplace={selectedMarketplace}
                pageValue={pageValue}
                pageContent={pageContent}
                allPeriod={allPeriod}
              />
            ) : (
              <AdvancedAnalyticsQuestions
                date={date}
                selectedBrand={selectedBrand}
                selectedCategory={selectedCategory}
                selectedApi={selectedApi}
                selectedMarketplace={selectedMarketplace}
                pageValue={pageValue}
                pageContent={pageContent}
                allPeriod={allPeriod}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export { AnaliticsReview };
