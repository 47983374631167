import { useState, useEffect } from 'react';
import { useRef } from 'react';
import { DropDownSelector } from '@lk-gtcom/ecomlab-components';
import { KonstantionAuthHttp, KonstantionAuthHttps } from '../../../fetchUrls';
import { validationEmail } from '../../../common/validationFunc';
import { InputDinamycPlaceholder } from '@lk-gtcom/ecomlab-components';
import { ButtonBasic } from '@lk-gtcom/ecomlab-components';
import close_icon from './img/close.svg';
import plus_icon from './img/bx-user-plus.svg.svg';
import Cookies from 'universal-cookie';
import { Checkbox } from '@lk-gtcom/ecomlab-components';
import './ModalAddEmployee.scss';

const list = [
  {
    label: 'Пространство 1',
  },
  {
    label: 'Пространство 2',
  },
  {
    label: 'Пространство 3',
  },
  {
    label: 'Пространство 4',
  },
  {
    label: 'Пространство 5',
  },
  {
    label: 'Пространство 6',
  },
  {
    label: 'Пространство 7',
  },
  {
    label: 'Пространство 8',
  },
  {
    label: 'Пространство 8',
  },
];

const ModalAddEmployee = ({ setShowModal, fetchGetEmployees, currentSpace }) => {
  const auth = new Cookies().get('auth');
  const client_id = localStorage.getItem('client_id');
  const [loginExecutor, setLoginExecutor] = useState('');
  const [errorEmail, setErrorEmail] = useState(false);

  const [selected, setSelected] = useState([]);
  const [errSelect, setErrSelect] = useState(false);

  const [checkboxValues, setCheckboxValues] = useState([]);
  const [heightContainer, setHeightContainer] = useState(0);
  const refContainer = useRef();

  console.log(currentSpace)

  const options_prop = [
    {
      label: 'Администратор',
      value: 7,
    },
    {
      label: 'Менеджер по товарам',
      value: 8,
    },
    {
      label: 'Менеджер склада',
      value: 9,
    },
  ];

  const fetchAddEmployee = () => {
    if (selected?.label) {
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${KonstantionAuthHttps}/web/users/employees`;
      } else {
        url = `${KonstantionAuthHttp}/web/users/employees`;
      }

      const body = JSON.stringify({
        client_executor_login: loginExecutor,
        role: selected?.label,
        space_id_list: [currentSpace?.id],
      });

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth}`,
      };

      fetch(`${url}`, { body, method: 'POST', headers })
        .then((res) => res.json())
        .then((json) => {
          fetchGetEmployees();
        })
        .catch((err) => console.error(err));
    }
  };

  const onValidateForm = () => {
    if (!validationEmail(loginExecutor)) {
      return setErrorEmail(true);
    }
    if (!selected?.value) {
      return setErrSelect(true);
    }

    fetchAddEmployee();
    setShowModal(false);
  };

  const heightColumn = () => {
    const height = refContainer?.current?.getBoundingClientRect().height;
    setHeightContainer(height);
  };

  useEffect(() => {
    heightColumn();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', heightColumn);

    return () => {
      window.removeEventListener('resize', heightColumn);
    };
  }, []);

  useEffect(() => {
    setErrSelect(false);
  }, [selected]);

  const optionsPropList = options_prop.map(({ label, value }) => (
    <label
      className="checkbox-container authorization-checkbox__container"
      key={label}
    >
      <input type="checkbox" className="checkbox" checked />
      <span className="checkbox-element "></span>
      <div className="text-and-icon-box">
        <p className="text_icon">{label}</p>
        <p className=""></p>
      </div>
    </label>
  ));

  return (
    <div
      className="blackout"
      style={{zIndex: 12}}
      onClick={(e) => {
        let el = e.target;
        if (el.classList.contains('blackout')) {
          setShowModal(false);
        }
      }}
    >
      <div className="modal-add-employee">
        <div className="modal-add-employee__header">
          <h3 className="modal-add-employee__title">Добавление сотрудника</h3>
          {/* <h3 className="modal-add-employee__title">Выберите пространство</h3> */}
        </div>

        <button
          className="modal-add-employee__btn-close"
          onClick={(e) => setShowModal(false)}
        >
          <img src={close_icon} />
        </button>

        <div className="modal-add-employee__content">
          <div ref={refContainer} className="modal-add-employee__left-content">
            <InputDinamycPlaceholder
              classname="autorization__input-field"
              err={errorEmail}
              placeholder="Введите email"
              textErr="Неверный email"
              type="e-mail"
              autoComplete={false}
              onChange={(e) => {
                setErrorEmail(false);
                setLoginExecutor(e);
              }}
              size='s'
            />

            <DropDownSelector
              options_prop={options_prop}
              state={selected}
              setState={(e) => setSelected(e)}
              placeholder="Выберите роль"
              err={errSelect}
              width="100%"
              size='s'
            />

            <label className="checkbox-box">
              <p className="modal-add-employee__text">
                На указанную почту будет отправлено письмо с уведомлением и
                ссылкой для подтверждения аккаунта сотрудника
              </p>
            </label>

            {/* <label className="checkbox-box">
              <Checkbox />
              <p className="modal-add-employee__text">
                Предоставляя персональные данные третьего лица для добавления
                сотрудника, я заверяю о наличии согласия такого лица на передачу
                Вам персональных данных, а также последующую обработку Вами
                персональных данных
              </p>
            </label> */}

            <ButtonBasic
              green
              text={
                <div className="modal-add-employee__content-btn">
                  <img src={plus_icon} />
                  Пригласить сотрудника
                </div>
              }
              width="100%"
              size="56px"
              onClick={(e) => onValidateForm()}
            />
          </div>
          {/* <div className="modal-add-employee__right-content">
            <div
              className="modal-add-employee__box-space"
              style={{ maxHeight: heightContainer }}
            >
              {spaceList?.length > 0 ? (
                spaceList?.map(({ id, name, author }, ind) => {
                  if (author?.id == client_id) {
                    return (
                      <button key={id} className="modal-add-employee__drop-btn">
                        <Checkbox
                          value={checkboxValues.includes(id)}
                          onChange={(e) => {
                            let index = checkboxValues.indexOf(id);
                            if (index === -1) {
                              setCheckboxValues([...checkboxValues, id]);
                            } else {
                              let new_values = checkboxValues.filter(
                                (el) => el != id
                              );
                              setCheckboxValues(new_values);
                            }
                          }}
                        />
                        {name}
                      </button>
                    );
                  }
                })
              ) : (
                <p className="modal-add-employee__no-data">Нет данных</p>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export { ModalAddEmployee };
