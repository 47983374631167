import { Link } from 'react-router-dom';
import { ButtonBasic, InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components"
import minus_icon from '../img/bx-minus.svg'
import './SettingsRow.scss'
import { useState } from 'react';

const SettingsRow = ({ icon, name, mp_name, mp_group, isAdmin, key, btnFunc }) => {

    const [role, setRole] = useState('')

    return (
        <div className='settings-row' key={key}>
            <div className='settings-row__name'>
                <img className='settings-row__icon' src={icon} />
                {name}
            </div>
            <div className='settings-row__tools'>

                {
                    isAdmin ?
                        <ButtonBasic
                            grey
                            width='40px'
                            minWidth='40px'
                            text={<img src={minus_icon} />}
                            onClick={(e) => btnFunc()}
                        />
                        :
                        null
                }
            </div>
        </div>
    )
}

export { SettingsRow }
