import { useState, useEffect } from 'react';
import {
  InputDinamycPlaceholder,
  ButtonBasic,
  CheckboxToggle,
} from '@lk-gtcom/ecomlab-components';
import { ColItemDrop } from '../ModalRules/ModalRules';
import useGeneralStore from '../../../store/general';
import customHeaders, { getSpace } from '../../../common/headers';
import { SelectedColorsComponent } from '../../SelectedColorsComponent/SelectedColorsComponent';
import { StepItem } from '../../StepItem/StepItem';
import close from './img/close.svg'
import './ModalAddRole.scss';


const colors = [
  {
    color: '#EA8612',
    color_name: 'оранжевый'
  },
  {
    color: '#B9A91D',
    color_name: 'желтый'
  },
  {
    color: '#21797E',
    color_name: 'темно-зеленый'
  },
  {
    color: '#25B5AC',
    color_name: 'бирюзовый'
  },
  {
    color: '#D848DB',
    color_name: 'розовый'
  },
  {
    color: '#7748DB',
    color_name: 'фиолетовый'
  },
  {
    color: '#1E291E',
    color_name: 'черный'
  },
  {
    color: '#3C72C3',
    color_name: 'синий'
  },
  {
    color: '#72D643',
    color_name: 'зеленый'
  },
  {
    color: '#C92929',
    color_name: 'красный'
  },
]

const available_rules = [
  {
    "id": 5,
    "name": "delete_all",
    "portal_name": "Удалить всё",
    "type": "action",
    "has_params": false,
    "description": "Тестовая подсказка. Менять в БД",
    "service_id": 3,
    "status": false
  },
  {
    "id": 6,
    "name": "delete_discount",
    "portal_name": "Удалять товары  по заданному порогу скидки",
    "type": "action",
    "has_params": true,
    "description": "Тестовая подсказка. Менять в БД",
    "service_id": 3,
    "status": false
  },
  {
    "id": 7,
    "name": "add_zero_discount",
    "portal_name": "Добавлять товары с нулевой скидкой",
    "type": "action",
    "has_params": false,
    "description": "Тестовая подсказка. Менять в БД",
    "service_id": 3,
    "status": false
  },
  {
    "id": 8,
    "name": "add_discount",
    "portal_name": "Добавлять всё с указанием порогового значения скидки",
    "type": "action",
    "has_params": true,
    "description": "Тестовая подсказка. Менять в БД",
    "service_id": 3,
    "status": false
  },
  {
    "id": 9,
    "name": "delete_stock_fbo",
    "portal_name": "Удалять товары  по заданному остатку FBO",
    "type": "action",
    "has_params": true,
    "description": "Тестовая подсказка. Менять в БД",
    "service_id": 3,
    "status": false
  },
  {
    "id": 10,
    "name": "delete_stock_fbs",
    "portal_name": "Удалять товары  по заданному остатку FBS",
    "type": "action",
    "has_params": true,
    "description": "Тестовая подсказка. Менять в БД",
    "service_id": 3,
    "status": false
  }

]

const ModalAddRole = ({
  setIsModal,
}) => {

  const selectedSpace = useGeneralStore((state) => state.selectedSpace);
  const headers = { ...customHeaders, ...getSpace(selectedSpace) };

  const [nameRole, setNameRole] = useState('')
  const [isActive, setIsActive] = useState(false)
  const [currentColor, setCurrentColor] = useState(null)

  const [currentCard, setCurrentCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(available_rules);
  const [disactive, setDisactive] = useState([]);

  const [date, setDate] = useState([
    new Date().getTime() - 604800000,
    new Date().getTime(),
  ]);
  const [dateFrom, dateTo] = date;

  const onStrategieToggle = (props) => {

  };

  const dragStartHandler = (e, card) => {
    // Перетаскиваемая карточка
    setCurrentCard(e);
  };

  const dragOverHandler = (e, card) => {
    e.preventDefault();
  };

  const dropHandler = (e, status) => {
    e.preventDefault();
    if (!currentCard) return;
    // onStrategieToggle({ ...currentCard, status });
  };

  const getClientParams = async (id, name, status) => {
    // await onGetParams(id, name, status);
    // onGetStrategyParams(id, name, status)
  };



  return (
    <div
      className="blackout"
      onClick={(e) => {
        let el = e.target;
        if (el.classList.contains('blackout')) {
          setIsModal(false);
        }
      }}
    >
      <div className="modal-add-role">
        <div className="modal-add-role__header">
          <h1 className="modal-add-role__title">
            {false
              ? `Редактирование`
              : 'Создание'}
          </h1>
          <InputDinamycPlaceholder
            changeValue={nameRole}
            placeholder="Название"
            onChange={(e) => setNameRole(e)}
            size='s'
          />

          <ButtonBasic
            grey
            minWidth='190px'
            width='190px'
            text='Сохранить изменения'
          />

          <div className="modal-add-role__toogle-checkbox">
            <span className={'modal-add-role__text_status'}>
              {isActive ? ' Активен' : ' Не активен'}
            </span>

            <CheckboxToggle
              color="blue"
              value={isActive}
              onChange={(e) => {
                setIsActive(e);
              }}
            />
          </div>

          <button className='modal-add-role__btn-close'
            onClick={(e) => setIsModal(false)}
          >
            <img src={close} />
          </button>
        </div>

        <StepItem
          title='Выберите цвет'
          description={
            <span>Заполните все данные, которые находятся ниже
              и нажмите на кнопку “Следующий шаг”. <span style={{ color: '#FF4E4E' }}>
                Красная звезда</span> выделены
              обязательные пункты для заполнения</span>}
        />

        <SelectedColorsComponent
          colors={colors}
          currentColor={currentColor}
          setCurrentColor={(e) => setCurrentColor(e)}
        />

        <StepItem
          title='Выберите доступы'
        />

        <div className='modal-add-role__columns'>
          <div className="column-group">
            <ColItemDrop
              title="Доступные"
              loading={loading}
              arr={disactive}
              onDragStart={(e) => dragStartHandler(e)}
              onDragOver={(e) => dragOverHandler(e)}
              onDrop={(e) => dropHandler(e, false)}
              onStrategieToggle={(e) => onStrategieToggle(e)}
              onGetParams={(e) => getClientParams(e)}
            />

            <ColItemDrop
              title="Активные"
              arr={active}
              loading={loading}
              onDragStart={(e) => dragStartHandler(e)}
              onDragOver={(e) => dragOverHandler(e)}
              onDrop={(e) => dropHandler(e, true)}
              onStrategieToggle={(e) => onStrategieToggle(e)}
              // onGetParams={(e) => getClientParams(e)}
              onGetParams={(e) => {
                const { id, name, status } = e;
                getClientParams(id, name, status);
              }}
              active={active}
            />
          </div>
        </div>

      </div>
    </div>
  );
};

export { ModalAddRole };
