import "./SelectedColorsComponent.scss"

const SelectedColorsComponent = ({
    colors,
    currentColor,
    setCurrentColor
}) => {

    return (
        <div className="selected-colors-component">
            <p className="selected-colors-component__label">Выбран: <span style={{
                color: currentColor?.color
            }}>{currentColor?.color_name ? currentColor?.color_name  : '-'}</span></p>
            <div className="selected-colors-component__content">
                {colors?.map((el) => {
                    return <div
                        onClick={(e) => {
                            setCurrentColor(el)
                        }}
                        className={currentColor?.color == el?.color ?
                            "selected-colors-component__color-block_active"
                            :
                            "selected-colors-component__color-block"
                        }
                        style={{ background: el?.color }}
                        key={el?.color}>
                    </div>
                })}
            </div>
        </div>
    )
}

export { SelectedColorsComponent }