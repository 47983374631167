import doc_icon from './img/doc_icon.png'
import "./TableWithoutData.scss"

const TableWithoutData = ({text}) => {

    return (
        <div className='table-without-data'>
            <div className='table-without-data__content'>
                <img src={doc_icon} />
                <p className='text'>{text}</p>
            </div>
        </div>
    )
}

export { TableWithoutData }