import plug from './img/plug_icon.svg'
import Rating from '@mui/material/Rating'
import customHeaders, { getSpace } from '../../../common/headers'
import StarIcon from '@mui/icons-material/Star'
import { SendMessage } from '../../SendMessage/SendMessage'
import { getFormatedDate } from '../../ChatReview/ChatReview'
import { useState, useEffect } from 'react'
import {
    ButtonBasic
} from "@lk-gtcom/ecomlab-components";
import { ChatMessage, UserMessage } from '../../ChatReview/ChatReview'
import { KonstantinMasterHttps, KonstantinMasterHttp, KonstantinFeedbackHttps, KonstantinFeedbackHttp } from '../../../fetchUrls'
import useGeneralStore from '../../../store/general'
import './ModalReview.scss'

const ModalReview = ({ setIsModal, questData, setQuestData, pageValue, selectedStatus }) => {
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }
    const [chatMessages, setChatMessages] = useState([])
    const [author, setAuthor] = useState('')
    const [chatInfo, setChatInfo] = useState({})
    // const [status, setStatus] = useState(false)
    const [answerValue, setAnswerValue] = useState('')
    const [createdAt, setCreatedAt] = useState()
    const {
        api_id, id, mp_id, rating, img, productName, offer_id, category_name, date, brand, review, product_name, product_valuation,
        created_at, primary_image, keyword_list, tag_list, status
    } = questData || {}
    const [isEdit, setIsEdit] = useState(false)
    const [answer, setAnswer] = useState('')


    const fetchChat = () => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${KonstantinMasterHttps}/wb/${pageValue}/chat?api_id=${api_id}&mp_id=${mp_id}&${pageValue === 'feedbacks' ? 'feedback_id' : 'question_id'}=${id}`
        } else {
            url = `${KonstantinMasterHttp}/wb/${pageValue}/chat?api_id=${api_id}&mp_id=${mp_id}&${pageValue === 'feedbacks' ? 'feedback_id' : 'question_id'}=${id}`
        }


        fetch(url, { method: 'GET', headers })
            .then(res => res.json())
            .then(json => {
                const { api_id, chat_id, customer_name, messages, opened } = json
                if (messages) setChatMessages(messages)
                setAuthor(customer_name)
                setChatInfo({ api_id, id: chat_id, name: customer_name, opened })
                setAnswerValue('')
                setIsEdit(false)
            })
            .catch(err => {
                console.error(err)
            })
    }

    const fetchAnswer = () => {
        if (answerValue) {
            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${KonstantinFeedbackHttps}/wildberries/${pageValue}/answer`
            } else {
                url = `${KonstantinFeedbackHttp}/wildberries/${pageValue}/answer`
            }

            const messageId = pageValue === 'feedbacks' ? { feedback_id: id } : { question_id: id }


            const body = JSON.stringify({
                api_id,
                text: answerValue,
                ...messageId
            })


            fetch(url, { body, method: 'POST', headers })
                .then(res => console.log(res.json()))
                .then(() => setIsModal(false))
                .catch(err => {
                    console.error(err)
                })
        }
    }

    const messageTypeChecker = (message, path) => {
        const { message_id, user_type } = message
        return (
            user_type === 'seller' ?
                <ChatMessage
                    setAnswer={(e) => setAnswer(e)}
                    setIsEdit={(e) => setIsEdit(e)}
                    key={message_id}
                    {...message}
                    date={date} />
                :
                <UserMessage
                    author={author}
                    rating={rating}
                    key={message_id}
                    // currentImg={currentImg}
                    {...message}
                    path={path ? path : false} />
        )
    }

    useEffect(() => {
        fetchChat()
    }, [questData, selectedSpace])


    return (
        <div className='blackout' onClick={(e) => {
            let el = e.target
            if (el.classList.contains('blackout')) {
                setIsModal(false)
                setQuestData({})
            }
        }}>
            <div className='modal-review'>
                <div className='message'>
                    <div className='icon-container'>
                        <img src={img ? img : primary_image} onError={e => e.target.src = plug} />
                        <div className={status === 'Отвечен' ? 'answered' : 'not-answered'}>{status}</div>
                    </div>
                    <div className='info-content'>
                        <h3 className='title'>{productName ? productName : product_name ? product_name : 'Нет названия'}</h3>
                        {keyword_list && <p className='text_grey'>Ключевые: {keyword_list?.slice(0, 3)?.join(', ')}</p>}
                        {tag_list && <p className='text_grey'>Теги: {tag_list?.slice(0, 3)?.join(', ')}</p>}
                    </div>
                    <div className='rating-and-date'>
                        {
                            (rating || product_valuation) &&
                            <div className='rating-color-box'>
                                {
                                    <Rating
                                        precision={1}
                                        defaultValue={rating ? rating : product_valuation ? product_valuation : ''}
                                        max={1}
                                        readOnly
                                        emptyIcon={<StarIcon style={{ opacity: '.1' }} />}
                                        icon={<StarIcon style={{ fill: '#faaf00' }} />}
                                        size="large" />
                                }
                                <p className='text_rating'>{rating ? rating : product_valuation ? product_valuation : ''}</p>
                            </div>
                        }

                        <p className='text_date'>{getFormatedDate(date ? date : created_at, 'date')}
                            <span> {getFormatedDate(date ? date : created_at, 'time')}</span>
                        </p>
                    </div>
                </div>

                <div className='chat__content'>
                    {
                        chatMessages?.map((el, ind) => messageTypeChecker(el, rating ? rating : product_valuation ? product_valuation : '', author))
                    }
                </div>

                <SendMessage
                    frequentAnswers
                    btn={selectedStatus == 'Модерация' ? <ButtonBasic
                        grey
                        width='auto'
                        text='Cохранить на модерации'
                    /> : null}
                    actionBtnText={selectedStatus == 'Модерация' ? 'Подтвердить' : 'Отправить'}
                    disabled={!chatInfo?.opened}
                    value={answerValue}
                    onChange={e => setAnswerValue(e)}
                    onSendAction={e => fetchAnswer()}
                    isEdit={isEdit}
                    setIsEdit={(e) => setIsEdit(e)}
                    message={answer} />
            </div>
        </div>
    )
}

export { ModalReview }