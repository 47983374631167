import { useState, useEffect } from "react"
import { useRef } from "react"
import dots_horizontal_rounded from './img/bx-dots-horizontal-rounded.svg'
import "./ButtonActionDrop.scss"

const ButtonActionDrop = ({
    btns = [],
    label = '',
    size,
    color,
    positionDrop,
    id = 1 }) => {

    const [isDrop, setIsDrop] = useState(false)
    const [top, setTop] = useState(0)
    const [left, setLeft] = useState(0)
    const ref = useRef()


    // Обработчик на клик вне элемента
    useEffect(() => {
        const isDrop = (e) => {
            let el = e.target
            if (!el.closest(`#button-action-drop-${label + id}`)) {
                setIsDrop(false)
            }
        }
        document.addEventListener('click', isDrop)

        return () => {
            document.removeEventListener('click', isDrop)
        }
    }, [])

    useEffect(() => {
        const ref_top = ref?.current.getBoundingClientRect().bottom
        const ref_left = ref?.current.getBoundingClientRect().right

        setLeft(ref_left)
        setTop(ref_top)
    }, [isDrop]);


    return (
        <div className='button-action-drop' id={`button-action-drop-${label + id}`}
            onClick={(e) => {
                e.stopPropagation()
                setIsDrop(!isDrop)

                let el = e.target;
                if (el.classList.contains(`#button-action-drop-${label + id}`)) {
                    setIsDrop(false)
                }
            }}
        >
            <button ref={ref}
                style={{
                    background: color ? color : ''
                }}
                className={`button-action-drop__btn ${size === 'm' ? 'button-action-drop__btn-medium' : ''} ${size === 's' ? 'button-action-drop__btn-small' : ''}`}>
                <img src={dots_horizontal_rounded} />
            </button>

            {isDrop &&
                <div className={'button-action-drop__drop-menu_active'}
                    style={{
                        left: left ? left - 160 : 0,
                        top: top ? top : 0,
                    }}
                >
                    <div className='button-action-drop__drop-menu-inside'
                    onMouseLeave={() => setIsDrop(false)}
                    >
                        {btns.map(({ btn, func }) =>
                            <button className='button-action-drop__drop-menu__btn'
                                onClick={(e) => {
                                    if (id) {
                                        func(id)
                                    } else {
                                        func(e)
                                    }

                                    setIsDrop(false)
                                }}
                            >{btn}</button>
                        )}
                    </div>
                </div>}
        </div>
    )
}

export { ButtonActionDrop }