import { useState, useEffect } from 'react';
import { InputDinamycPlaceholder } from '@lk-gtcom/ecomlab-components';
import {
  KonstantinAccountInSettingsHttps,
  KonstantinAccountInSettingsHttp,
} from '../../fetchUrls';
import Cookies from 'universal-cookie';
import { ModalSuccessful } from '../Modal/ModalSuccessful/ModalSuccessful';
import headers from '../../common/headers';
import initTranslations from '../../i18n';
import './AccountSettings.scss';

const namespaces = ['AccountSettings'];

const AccountSettings = ({
  changeDataUser,
  setChangeDataUser,
  setValid
}) => {

  const [i18n, seti18n, ready] = useState({}, { useSuspense: false });

  const translate = i18n?.t;
    const t = (key) => {
      if (typeof translate === 'function') {
        return translate(key);
      }
    };
  
    const initTranslation = async () => {
      const { i18n } = await initTranslations('en', namespaces);
      seti18n(i18n);
    };
  
    useEffect(() => {
      initTranslation();
    }, []);

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [tel, setTel] = useState('');

  const [errName, setErrName] = useState(false);
  const [errEmail, setErrEmail] = useState(false);
  const [errTel, setErrTel] = useState(false);
  const [err, setErr] = useState('');

  const [isModal, setIsModal] = useState(false);
  const auth = new Cookies().get('auth');

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const telRegex = /^\+90 \d{3} \d{3} \d{2} \d{2}$/;

  const validateForm = (isEmailValid, isNameValid, isTelValid) => {
    setValid(isEmailValid && isNameValid && isTelValid);
  };

  const handleEmailChange = (e) => {
    const value = e;
    setEmail(value);
    validateForm(emailRegex.test(value), name.length >= 3, telRegex.test(tel));
  };

  const handleNameChange = (e) => {
    const value = e;
    setName(value);
    validateForm(emailRegex.test(email), value.length >= 3, telRegex.test(tel));
  };

  const handleTelChange = (e) => {
    const value = e;
    setTel(value);
    validateForm(
      emailRegex.test(email),
      name.length >= 3,
      telRegex.test(value)
    );
  };

  const validateEmailBlur = () => {
    setErrEmail(!emailRegex.test(email));
  };

  const validateNameBlur = () => {
    setErrName(name.length < 3);
  };

  const validateTelBlur = () => {
    setErrTel(!telRegex.test(tel));
  };

  const fetchUser = () => {
    const url = window.location.hostname.match('localhost')
      ? `${KonstantinAccountInSettingsHttp}/web/users/data`
      : `${KonstantinAccountInSettingsHttps}/web/users/data`;

    fetch(url, { method: 'GET', headers })
      .then((res) => res.json())
      .then(({ email, name, phone_number }) => {
        if (email) setEmail(email);
        if (name) setName(name);
        if (phone_number) setTel(phone_number);
      })
      .catch((err) => console.error(err));
  };

  const fetchChangeUserData = () => {
    const url = window.location.hostname.match('localhost')
      ? `${KonstantinAccountInSettingsHttp}/web/users/data`
      : `${KonstantinAccountInSettingsHttps}/web/users/data`;

    const body = JSON.stringify({ name, email, phone_number: tel });


    fetch(url, { body, method: 'POST', headers })
      .then((res) =>
        res.json().then((json) => {
          if (json?.detail === 'User does not exist') {
            setErr('User does not exist');
          } else {
            setIsModal(true);
          }
        })
      )
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (changeDataUser) {
      validateNameBlur();
      validateEmailBlur();
      validateTelBlur();

      if (!errName && !errEmail && !errTel) {
        fetchChangeUserData();
      }
      setChangeDataUser(false);
    }
  }, [changeDataUser]);

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <>
      {isModal && (
        <ModalSuccessful
          title="Account details successfully changed"
          setIsModal={(e) => setIsModal(e)}
        />
      )}
      <div className="account-settings">
        <h2 className="title">{t('AccountSettings:account_settings')}</h2>

        <InputDinamycPlaceholder
          onChange={handleEmailChange}
          onBlur={validateEmailBlur}
          type="text"
          err={errEmail}
          textErr="Invalid e-mail"
          classname="autorization__input-field"
          placeholder="Email"
          changeValue={email}
          autoComplete={false}
        />

        <InputDinamycPlaceholder
          onChange={handleNameChange}
          onBlur={validateNameBlur}
          type="text"
          err={errName}
          textErr="Enter the name"
          classname="autorization__input-field"
          placeholder="User name"
          changeValue={name}
          autoComplete={false}
        />

        <InputDinamycPlaceholder
          onChange={handleTelChange}
          onBlur={validateTelBlur}
          type="tel"
          err={errTel}
          textErr="Invalid phone number"
          classname="autorization__input-field"
          placeholder="Phone number"
          changeValue={tel}
          autoComplete={false}
          mask={'+{9\\0} 000 000 00 00'}
        />
      </div>

      <div className="err-box">
        {err && <span className="text text_red">{err}</span>}
      </div>
    </>
  );
};

export { AccountSettings };
