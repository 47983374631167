import { Link, Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { TableBox, ButtonBasic } from '@lk-gtcom/ecomlab-components';
import { ModalAddEmployee } from '../Modal/ModalAddEmployee/ModalAddEmployee';
import { KonstantionAuthHttps, KonstantionAuthHttp } from '../../fetchUrls';
import Cookies from 'universal-cookie';
import { ModalSuccessfullyAddingAnEmployee } from '../Modal/ModalSuccessfullyAddingAnEmployee/ModalSuccessfullyAddingAnEmployee';
import headers from '../../common/headers';
import initTranslations from '../../i18n';
import './Employees.scss';

const namespaces = ['Employees'];

const Employees = () => {

  const [i18n, seti18n, ready] = useState({}, { useSuspense: false });

  const translate = i18n?.t;
  const t = (key) => {
    if (typeof translate === 'function') {
      return translate(key);
    }
  };

  const initTranslation = async () => {
    const { i18n } = await initTranslations('en', namespaces);
    seti18n(i18n);
  };

  useEffect(() => {
    initTranslation();
  }, []);

  const [loading, setLoading] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showModalEmployeeAdded, setShowModalEmployeeAdded] = useState(false);

  const [employeeData, setEmployeeData] = useState([]);
  const [employeeHeaders, setEmployeeHeaders] = useState([]);
  const [employeeSort, setEmployeeSort] = useState([]);
  const [employeeColumnSize, setEmployeeColumnSize] = useState([]);
  const employeeRef = useRef();

  const email = localStorage.getItem('email');
  const auth = new Cookies().get('auth');

  const fetchGetEmployees = (params) => {
    let sort;
    let filtersParam;

    if (params) {
      [sort, filtersParam] = params;
    }
    setLoading(true);
    let url;
    if (!window.location.hostname.match('localhost')) {
      url = `${KonstantionAuthHttps}/web/users/employees/list${sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
        }`;
    } else {
      url = `${KonstantionAuthHttp}/web/users/employees/list${sort?.length > 0
          ? '?order_param=' + sort[0] + '&order_param_direction=' + sort[1]
          : ''
        }`;
    }


    const body = JSON.stringify({
      limit: 50,
      offset: 1,
      filters: filtersParam,
    });

    fetch(url, { body, method: 'POST', headers })
      .then((res) => res.json())
      .then((json) => {
        const { data, labels, sort, column_size, total } = json;
        if (data) setEmployeeData(data);
        if (labels) setEmployeeHeaders(labels);
        if (sort) setEmployeeSort(sort);
        if (column_size) setEmployeeColumnSize(column_size);
        if (total) employeeRef.current.setTotal(total);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  const onDeleteEmployees = (param) => {
    if (param?.id) {
      setLoading(true);
      let url;
      if (!window.location.hostname.match('localhost')) {
        url = `${KonstantionAuthHttps}/web/users/employees/${param.id}`;
      } else {
        url = `${KonstantionAuthHttp}/web/users/employees/${param.id}`;
      }



      fetch(url, { method: 'DELETE', headers })
        .then((res) => res.json())
        .then((json) => {
          fetchGetEmployees();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchGetEmployees();
  }, []);

  return (
    <>
      {showModalEmployeeAdded && (
        <ModalSuccessfullyAddingAnEmployee
          setIsModal={(e) => setShowModalEmployeeAdded(e)}
        />
      )}
      {showModal ? (
        <ModalAddEmployee
          setShowModal={(e) => setShowModal(e)}
          fetchGetEmployees={(e) => fetchGetEmployees(e)}
        />
      ) : null}
      <div className="employees">
        <h3 class="employees__title">{t('Employees:Employees')}</h3>

        <TableBox
          lang="en"
          loading={loading}
          fetchedData={employeeData}
          headers={employeeHeaders}
          child={
            <ButtonBasic
              green
              text={t('Employees:Invite_employee')}
              onClick={(e) => setShowModal(true)}
              size="32px"
            />
          }
          onDelete={(e) => onDeleteEmployees(e)}
          fetchCallback={(e) => fetchGetEmployees(e)}
          ref={employeeRef}
          sort={employeeSort}
          columnSize={employeeColumnSize}
          hideExport
        />

        {/* {tableContent === 'Сотрудники' ?
                    !loading ?
                        <div className='employees-list'>
                            {employees.map(({ email, name, id }) =>
                                <div key={id} className='employees-list__item'>
                                    <div className='name-box'>
                                        <p>{email}</p>
                                        <p>{name}</p>
                                    </div>
                                    <div className='btn-box'>
                                        <button onClick={(e) => {
                                            onDeleteEmployees(id)
                                        }} className='btn__delete-light'></button>
                                    </div>
                                </div>
                            )}
                            {employees.length === 0 && <p style={{ marginTop: '24px' }}>Нет сотрудников</p>}
                        </div>
                        :
                        <p>Загрузка...</p>

                    :
                    <p></p>
                } */}
      </div>
    </>
  );
};

export { Employees };
