import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { Tabs, ButtonBasic } from '@lk-gtcom/ecomlab-components';
import { AccountSettings } from '../AccountSettings/AccountSettings';
import { AccountNotifications } from '../AccountNotifications/AccountNotifications';
import { ModalPasswordRecovery } from '../Modal/ModalPasswordRecovery/ModalPasswordRecovery';
import { Mail } from '../PasswordRecoveryBox/Mail/Mail';
import { AccountSafety } from '../AccountSafety/AccountSafety';
import { ModalAddNotificationMethod } from '../Modal/ModalAddNotificationMethod/ModalAddNotificationMethod';
import { ModalAddTypeNotification } from '../Modal/ModalAddTypeNotification/ModalAddTypeNotification';
import initTranslations from '../../i18n';
import './AccountManagement.scss';

const namespaces = ['AccountManagement'];

const AccountManagement = ({
  setIsModalActive,
  isModalActive,
  setConnectedModal,
  isModalFeedback,
}) => {

  const activeLang = 'tur'

  const [i18n, seti18n, ready] = useState({}, { useSuspense: false });

  const translate = i18n?.t;
  const t = (key) => {
    if (typeof translate === 'function') {
      return translate(key);
    }
  };

  const initTranslation = async () => {
    const { i18n } = await initTranslations('en', namespaces);
    seti18n(i18n);
  };

  useEffect(() => {
    initTranslation();
  }, []);


  const btn_tab = [
    {
      label: t('AccountManagement:tabs_1'),
      value: 'my-details',
    },
    {
      label: t('AccountManagement:tabs_2'),
      value: 'notifications',
    },
    {
      label: t('AccountManagement:tabs_3'),
      value: 'safety',
    },
  ];
  const [pageValue, setPageValue] = useState(btn_tab[0].value);
  const path = useParams();
  const navigate = useNavigate();
  const activeTab = useParams()['*'];
  const [showModalPasswordRecovery, setShowModalPasswordRecovery] =
    useState(false);
  const [showModalChangedPassword, setShowModalChangedPassword] =
    useState(false);
  const [showModalSendPassword, setShowModalSendPassword] = useState(false);
  const [showAddMethodNotification, setShowAddMethodNotification] =
    useState(false);
  const [email, setEmail] = useState('');
  const [changeDataUser, setChangeDataUser] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [valid, setValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false); //change pass

  useEffect(() => {
    if (path['*'] === '') {
      navigate('my-details');
    }
  }, []);


  useEffect(() => {
    setPageValue(activeTab);
  }, [activeTab]);





  return (
    <>
      {showModalPasswordRecovery && (
        <ModalPasswordRecovery
          setIsModal={(e) => setShowModalPasswordRecovery(e)}
        />
      )}
      {showModalSendPassword && (
        <Mail
          text={`Password has been sent to your e-mail`}
          setIsModal={(e) => setShowModalSendPassword(e)}
        />
      )}
      {showModalChangedPassword && (
        <Mail
          title="Password changed successfully"
          text="You can always change your password in your personal account"
          setIsModal={(e) => setShowModalChangedPassword(e)}
        />
      )}
      {showAddMethodNotification && (
        <ModalAddNotificationMethod
          setIsModal={(e) => setShowAddMethodNotification(e)}
        />
      )}
      <div className="account-management">
        <h1 class="title_main">{t('AccountManagement:title')}</h1>
        <Tabs tabs={btn_tab} pageValue={pageValue} />
        <div className="account-management__content">
          <Routes>
            <Route
              path="my-details"
              element={
                <AccountSettings
                  setValid={setValid}
                  changeDataUser={changeDataUser}
                  setChangeDataUser={(e) => setChangeDataUser(e)}
                />
              }
            />

            <Route
              path="notifications"
              element={
                <AccountNotifications
                  setConnectedModal={(e) => setConnectedModal(e)}
                  setIsModalActive={(e) => setIsModalActive(e)}
                  setIsModal={(e) => setShowAddMethodNotification(e)}
                  isModalActive={isModalActive}
                />
              }
            />
            <Route
              path="safety"
              element={
                <AccountSafety
                  changePassword={changePassword}
                  setChangePassword={(e) => setChangePassword(e)}
                  setShowModalPasswordRecovery={(e) =>
                    setShowModalPasswordRecovery(e)
                  }
                  setIsFormValid={setIsFormValid}
                />
              }
            />
          </Routes>
          <div className="group-btn">
            {pageValue != 'notifications' && (
              <ButtonBasic
                blue
                text="Save changes"
                width="200px"
                size="40px"
                onClick={(e) => {
                  if (pageValue === 'my-details') {
                    setChangeDataUser(true);
                  }
                  if (pageValue === 'safety') {
                    setChangePassword(true);
                  }
                }}
                disabled={pageValue === 'my-details' ? !valid : !isFormValid}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { AccountManagement };
