import { useNavigate } from 'react-router-dom';
import "./ProfileSettings.scss"

const ProfileSettings = ({
    onLogOutHandler,
    setActiveItems,
    setActiveSubItems
}) => {

    const navigate = useNavigate();

    return (
        <div className='profile-settings'
            onClick={(e) => {
                setActiveItems(null)
                setActiveSubItems(null)
            }}>
            <button className='profile-settings__btn' onClick={(e) => {
                navigate(`/settings/space`)
            }}>Settings</button>
            <button className='profile-settings__btn'>Language</button>
            <button className='profile-settings__btn profile-settings__btn_exit' onClick={e => onLogOutHandler(e)}>Logout</button>
        </div>
    )
}

export { ProfileSettings }