import { Link } from 'react-router-dom';
import { ExecutorPlaceholder } from '../../../OptionsExecutor/OptionsExecutor';
import { ButtonBasic, InputDinamycPlaceholder } from "@lk-gtcom/ecomlab-components"
import minus_icon from '../img/bx-minus.svg'
import './EcexutorRow.scss'
import { useState } from 'react';

const EcexutorRow = ({ key, name, login, id, color, isAdmin = true, addNewMember, status = false }) => {

    const [role, setRole] = useState('')

    return (
        <div className='executor-row' key={key}>
            <div className='executor-row__name'>
                <ExecutorPlaceholder
                    name={name}
                    email={login}
                    color={color}
                    isShowInfo
                    hideTooltip
                />
            </div>
            <div className='executor-row__tools'>
                <InputDinamycPlaceholder
                    changeValue={role}
                    placeholder="Роль пользователя в пространстве"
                    onChange={(e) => setRole(e)}
                    size='s'
                />

                {
                    isAdmin ?
                        <ButtonBasic
                            grey
                            width='40px'
                            minWidth='40px'
                            text={<img src={minus_icon} />}
                            onClick={(e) => addNewMember([id])}
                        />
                        :
                        null
                }
            </div>
        </div>
    )
}

export { EcexutorRow }
