import { ButtonBasic } from "@lk-gtcom/ecomlab-components";
// import { ExecutorPlaceholder } from "../../components/OptionsExecutor/OptionsExecutor";
import customHeaders, { getSpace } from "../../common/headers";
import { RavshanHttps, RavshanHttp } from "../../fetchUrls";
import useGeneralStore from "../../store/general";
import { useState, useEffect } from "react";
import user_icon from './img/bx-user.svg'
import user_circle_icon from './img/bx-user-circle.svg'
import group_icon from './img/bx-group.svg'
import { TabsGreen } from "../../components/TabsGreen/TabsGreen";
import { ButtonActionDrop } from "../../components/Buttons/ButtonActionDrop/ButtonActionDrop";
import pin_icon from './img/bx-pin.svg'
import star_icon from './img/bxs-star.svg'
import './SpacePage.scss';





// const arrNames = (arr_names) => {
//     return arr_names?.map(({ name, login, color, id }, ind) => {
//         return <ExecutorPlaceholder
//             key={name + id + '' + color + login + ind}
//             email={login}
//             name={name}
//             color={color}
//         />
//     })
// }

const client_id = localStorage.getItem('client_id');

const CardSpase = ({
    spaceData,
    setIsEditSpace,
    setIsModal,
    deleteFunc,
    refreshFetch,
    setCurrentSpace
}, ind) => {

    const {
        setPageValue1
    } = useGeneralStore();


    const is_admin = spaceData?.is_admin
    const btns = [
        {
            btn: 'Редактировать',
            func: () => { },
        },
        {
            btn: 'В избранное',
            func: () => { },
        },
        {
            btn: 'Закрепить',
            func: () => { },
        },
        {
            btn: 'Удалить',
            func: () => {
                deleteFunc(spaceData.id)
                setTimeout(() => refreshFetch(), 500)
            },
        },
    ];



    return (
        <div className='card-space' key={spaceData.name + ind}
            onClick={(e) => {
                setCurrentSpace(spaceData)
                setIsEditSpace(true)
                setPageValue1('employee')
                setIsModal(true)
            }}
        >
            <div className='card-space__header'>
                <div className='card-space__header-panel'>
                    {is_admin ?
                        <div className="card-space__admin-badge">
                            <img src={user_circle_icon} /> Админ
                        </div>
                        :
                        <div className="card-space__user-badge">
                            <img src={user_icon} /> Сотрудник
                        </div>
                    }

                    <img src={star_icon} />
                    <img src={pin_icon} />

                    {is_admin && <ButtonActionDrop
                        id={spaceData.id}
                        btns={btns}
                        size={'s'}
                    />}
                </div>


                <h3 className='card-space__title'>{spaceData.name}</h3>

            </div>
            <div className='card-space__content'>
                <button className="card-space__btn"
                    onClick={(e) => {
                        e.stopPropagation()
                        setCurrentSpace(spaceData)
                        setIsEditSpace(true)
                        setPageValue1('employee')
                        setIsModal(true)
                    }}
                >Сотрудники</button>
                <button className="card-space__btn"
                    onClick={(e) => {
                        e.stopPropagation()
                        setCurrentSpace(spaceData)
                        setIsEditSpace(true)
                        setPageValue1('settings')
                        setIsModal(true)
                    }}
                >Подключения</button>
                <button className="card-space__btn"
                    onClick={(e) => {
                        e.stopPropagation()
                        setCurrentSpace(spaceData)
                        setIsEditSpace(true)
                        setPageValue1('roles')
                        setIsModal(true)
                    }}
                >Роли</button>

                {/* {arrNames(spaceData.employees, '')?.splice(0, 6)}
                {(spaceData.employees?.length > 6) && <p className='sum_executor'>{'+' + (spaceData.employees?.length - 6)}</p>} */}
            </div>
        </div>
    )
}

const SpacePage = ({
    setIsModalCreatingNewSpace,
    setIsEditSpace,
    isModalCreatingNewSpace,
    fetchGetSpace, spaceList,
    setCurrentSpace,
    currentSpace,
    setIsModalActive
}) => {

    const [isModal, setIsModal] = useState(false)
    const selectedSpace = useGeneralStore(state => state.selectedSpace)
    const headers = { ...customHeaders, ...getSpace(selectedSpace) }

    const [status, setStatus] = useState(null)

    const currentSpaceList = () => {
        let current_list

        if (status == 'admin') {
            current_list = spaceList.filter(({ author }) => {
                return author?.id == client_id
            })
            return current_list
        }

        if (status == 'employee') {
            current_list = spaceList.filter(({ author }) => author?.id != client_id)
            return current_list
        }

        if (status == 'all') {
            return spaceList
        }

    }

    const btns = [
        {
            label: 'Все',
            count: spaceList.length, 
            value: 'all',
            icon: group_icon
        },
        {
            label: 'Админ',
            count: spaceList.filter(({ author }) => author?.id == client_id)?.length,
            value: 'admin',
            icon: user_circle_icon
        },
        {
            label: 'Сотрудник',
            count: spaceList.filter(({ author }) => author?.id != client_id)?.length,
            value: 'employee',
            icon: user_icon
        }
    ]

    useEffect(() => {
        setStatus(btns[0]?.value)
    }, []);

    // Удаление пространства
    const fetchDeleteSpace = (id) => {
        let url
        if (!window.location.hostname.match('localhost')) {
            url = `${RavshanHttps}/api/space/`
        } else {
            url = `${RavshanHttps}/api/space/`
        }

        const body = JSON.stringify({
            id: id
        })

        fetch(url, { body, method: 'DELETE', headers })
            .then(res => res.json)
            .then((json) => {
                console.log(json)
            })
            .catch(err => {
                console.error(err)
            })

    }

    useEffect(() => {
        fetchGetSpace()
    }, [selectedSpace]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            fetchGetSpace()
        }, 500)

        return () => clearTimeout(timeoutId);
    }, [isModalCreatingNewSpace]);

    // const replaceWithLink = (linkValue) => {
    //     const fullContent = target.innerText

    //     const { text, startIndex, endIndex } = selectionData
    //     target.innerHTML = `${fullContent?.slice(0, startIndex)}<a class='link' title=${titleValue}  href=${linkValue} target='_blank'>${text}</a>${fullContent?.slice(endIndex, fullContent?.length)}`
    //     resetData()
    // }




    return (
        <>

            <div className='space-page'>
                <h1 className='space-page__title'>Пространства</h1>

                <ButtonBasic
                    green
                    text='Создать пространство'
                    width='210px'
                    onClick={(e) => setIsModalCreatingNewSpace(true)}
                />

                <TabsGreen
                    btns={btns}
                    currentBtn={status}
                    setCurrentBtn={(e) => setStatus(e)}
                    checkValue={true}
                />

                <div className='space-page__content'>
                    {currentSpaceList()?.map((el) => {
                        return <CardSpase
                            setCurrentBtn={(e) => setStatus(e)}
                            spaceData={el}
                            allExecutors={[]}
                            setCurrentSpace={(e) => setCurrentSpace(e)}
                            setIsEditSpace={(e) => setIsEditSpace(e)}
                            setIsModal={(e) => setIsModalCreatingNewSpace(e)}
                            deleteFunc={(e) => fetchDeleteSpace(e)}
                            refreshFetch={(e) => fetchGetSpace(e)}
                        />
                    })}
                </div>
            </div>
        </>

    )
}

export { SpacePage }