import info_circle from './img/bx-info-circle.svg';
import "./StepItem.scss"

const StepItem = ({ title, description }) => {

    return (
        <div className="step-item-component" key={title + description}>
            <p className="step-item-component__step-info">
                <img src={info_circle} />
                {title}
            </p>
            <p className="step-item-component__step-description">
                {description}
            </p>
        </div>
    )
}

export { StepItem }